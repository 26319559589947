import { Alert } from 'flowbite-react';
import React from 'react';
import { ArrowRight, Check, ChevronRight, Clipboard, X } from 'react-feather';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer/Footer';
import { HiInformationCircle } from 'react-icons/hi';
import Navbar from '../../../components/Navbar/Navbar';
import Select from 'react-select';

const ContactMobile = ({
  submitted,
  submitting,
  setBody,
  setEmail,
  email,
  setAccId,
  setName,
  setInfo,
  info,
  blank,
  error,
  body,
  handleSubmitForm,
  about,
  setAbout,
}) => {
  const options = [
    { value: 'items', label: 'Items' },
    { value: 'orders', label: 'Orders' },
    { value: 'payouts', label: 'Payouts' },
    { value: 'checkout', label: 'Checkout' },
    { value: 'account', label: 'Account' },
    { value: 'feature', label: 'Feature request' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <>
      <Navbar />
      <div className="h-screen bg-white flex flex-col gap-1 items-center justify-center w-full p-4">
        {submitted ? (
          ''
        ) : (
          <>
            <div className="flex justify-between items-end w-full">
              <div className="flex flex-col items-start w-full">
                <p className="text-lg text-stone-800">Message Us</p>
                <p className="text-stone-600 text-xs">
                  Send a message to us below!
                </p>
              </div>
            </div>
          </>
        )}
        {submitted ? (
          <div className="w-full border border-gray-200 rounded-md flex flex-col gap-6 justify-center items-center p-4">
            <div className="w-full flex items-center justify-center gap-2 border border-gray-200 rounded-md p-2">
              <Check size={16} className="text-green-400" />
              <p className="text-stone-800 text-xs">
                Your message has been sent
              </p>
            </div>
            <p className="text-xs text-stone-800 text-center w-72">
              We appreciate you messaging us and we will respond soon. Please
              keep an eye on your email inbox.
            </p>

            <div className="flex flex-col items-center">
              <p className="text-xs text-stone-600 text-center">
                We will reply to:
              </p>
              <p className="text-xs text-stone-600 text-center font-bold">
                {email}
              </p>
            </div>

            <Link
              to="/"
              className="rounded-md flex text-xs items-center justify-center p-2 bg-gray-200 text-stone-800 "
            >
              Return Home
            </Link>
          </div>
        ) : (
          <>
            <form
              onSubmit={handleSubmitForm}
              className="border border-gray-200 rounded-md flex flex-col gap-2 p-4 w-full"
            >
              {error ? (
                <Alert color="failure" rounded icon={HiInformationCircle}>
                  {error}
                </Alert>
              ) : (
                ''
              )}

              {blank.email || blank.name || blank.body ? (
                <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                  <X size={16} className="text-red-500" />
                  <p className="text-stone-800 text-xs">
                    Please fill out all required feilds
                  </p>
                </div>
              ) : (
                ''
              )}
              <div className="flex flex-col">
                <p className="text-stone-600 text-xs">Messaging about</p>
                {/* <input
                  type="text"
                  className={`border text-xs ${
                    blank.name ? 'border-red-300' : 'border-gray-200'
                  } hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 outline-none bg-gray-50`}
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                /> */}
                <Select
                  options={options}
                  onChange={(value) => setAbout(value)}
                  value={about}
                  placeholder="Messaging about"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  isSearchable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: 'rgb(229 231 235)',
                      backgroundColor: 'rgb(249 250 251)',
                      borderWidth: 1,
                      '&:hover': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      '&:focus': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      fontSize: '12px',
                      borderRadius: '.375rem',
                      boxShadow: 'none',
                      zIndex: 999,
                      position: 'relative',
                      height: 35,
                      minHeight: 35,
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: 35,
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 999,
                      fontSize: '12px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? 'rgb(229 231 235)'
                        : state.isFocused
                        ? 'rgb(249 250 251)'
                        : '',
                      color: 'black',
                    }),
                  }}
                  className="w-full"
                />
              </div>

              <div className="flex flex-col">
                <p className="text-stone-600 text-xs">Email</p>
                <input
                  type="text"
                  className={`border text-xs ${
                    blank.email ? 'border-red-300' : 'border-gray-200'
                  } focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 outline outline-0 bg-gray-50`}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>

              {about?.value === 'account' ? (
                <div className="flex flex-col">
                  <p className="text-stone-600 text-xs">Account ID(optional)</p>
                  <input
                    type="text"
                    className={`border text-xs border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 outline outline-0 bg-gray-50`}
                    placeholder="Account ID"
                    onChange={(e) => setAccId(e.target.value)}
                  />
                  <div className="w-full flex justify-end">
                    <button
                      type="button"
                      onClick={() => setInfo(!info)}
                      className="text-stone-600 text-xs"
                    >
                      Where is this?
                    </button>
                  </div>
                  {info && (
                    <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md p-2">
                      <div className="flex items-center gap-1">
                        <p className="text-stone-600 text-xs">
                          Once signed in, go to
                        </p>
                        <div className="flex items-center gap-1 bg-gray-200 rounded-md p-1">
                          <p className="text-xs text-stone-600">Settings</p>
                          <ArrowRight size={12} />
                          <p className="text-xs text-stone-600">Account</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-stone-600 text-xs flex items-center">
                          Click <Clipboard size={14} className="mr-1 ml-1" /> to
                          copy account ID to clipboard
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}

              <div className="flex flex-col">
                <p className="text-stone-600 text-xs">Message</p>
                <textarea
                  type="text"
                  className={`border text-xs ${
                    blank.body ? 'border-red-300' : 'border-gray-200'
                  } focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 outline outline-0 bg-gray-50 h-24 resize-none`}
                  placeholder="How can we help?"
                  onChange={(e) => setBody(e.target.value)}
                  maxLength={350}
                />
                <div className="w-full flex justify-end">
                  <p className="text-xs text-stone-600">{body.length}/350</p>
                </div>
              </div>
              <button
                type="submit"
                className="w-full h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
                disabled={submitting}
              >
                {submitting ? 'Sending...' : 'Send'}
              </button>
            </form>
            <div className="flex justify-start text-left w-full">
              <p className="text-xs text-stone-600">
                Or email the builder:{' '}
                <span className="font-bold">justin@fruntt.com</span>
              </p>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ContactMobile;
