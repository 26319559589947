import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import DesktopForm from './DesktopForm';
import MobileForm from './MobileForm';
import { isMobile } from 'react-device-detect';
import { uploadImageRequest } from '../../../api/requests';
import { useAddDigitalProductMutation } from '../../../api/productsApiSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { showNotification } from '../../../redux/toastSlice';
import SideNavbar from '../../../components/SideNavbar';
import { Settings } from 'react-feather';

const NewItem = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  //form state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [published, setPublished] = useState(false);
  const [digitalType, setDigitalType] = useState('');
  const [link, setLink] = useState('');
  const [callToAction, setCallToAction] = useState({});
  const [payChoice, setPayChoice] = useState(false);
  const [suggestedPrice, setSuggestedPrice] = useState('');
  const [productContent, setProductContent] = useState('');
  const [dupLink, setDupLink] = useState('');
  const [info, setInfo] = useState('');
  const [url, setUrl] = useState('');
  const [free, setFree] = useState(false);
  const [marketplace, setMarketplace] = useState(false);
  const [category, setCategory] = useState([]);
  const [platform, setPlatform] = useState('Notion');
  const [addingProduct, setAddingProduct] = useState(false);
  const [versions, setVersions] = useState([]);
  const [versionTitle, setVersionTitle] = useState('');
  const [versionSum, setVersionSum] = useState('');
  const [versionPrice, setVersionPrice] = useState(0);
  const [add, setAdd] = useState(false);
  const [urlTaken, setUrlTaken] = useState(false);
  const [refund, setRefund] = useState(false);
  const [reTitle, setReTitle] = useState('');
  const [reText, setReText] = useState('');

  const [emptyFields, setEmptyFields] = useState([]);
  const [errMsgs, setErrMsgs] = useState([]);

  const [addDigitalProduct, result] = useAddDigitalProductMutation();

  const handleType = (value) => {
    setDigitalType(value.value);
  };

  const handleAction = (value) => {
    setCallToAction(value);
  };

  const handleCategory = (value) => {
    setCategory(value);
  };

  const handlePlatform = (value) => {
    setPlatform(value.value);
    setCategory([]);
  };

  const handleCancel = () => {
    navigate('/dashboard/item');
  };

  const handleProductContent = (edits) => {
    setProductContent(edits);
  };

  const handleAddVariation = () => {
    setVersions([
      ...versions,
      {
        title: versionTitle,
        description: versionSum,
        price: versionPrice,
        link: '',
      },
    ]);
    setVersionPrice(0);
    setVersionSum('');
    setVersionTitle('');
    setAdd(false);
  };

  const handleRemoveVariation = (index) => {
    const newVersions = [...versions];
    newVersions.splice(index, 1);
    setVersions(newVersions);
  };

  const setLinkForIndex = (index, link, title) => {
    const newVersions = [...versions];
    newVersions[index].link = link;
    setVersions(newVersions);
    const updatedErrMsgs = errMsgs.filter(
      (err) =>
        err.msg !==
        `${
          platform === 'Notion' ? 'Duplicate' : 'Remix'
        } link for ${title} variation missing`
    );
    setErrMsgs(updatedErrMsgs);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    setAddingProduct(true);

    let coverPicUrl;
    let coverPicKey;
    let uploadedFiles = [];

    //try to upload cover image and all files first
    //if request is success
    //send request to create product

    const errs = [];
    if (!title.trim()) {
      errs.push({ type: 'detail', msg: 'Item title missing' });
    }
    if (!url.trim()) {
      errs.push({ type: 'detail', msg: 'Item URL missing' });
    }
    if (!dupLink.trim()) {
      errs.push({
        type: 'content',
        msg: `${platform === 'Notion' ? 'Duplicate' : 'Remix'} link missing`,
      });
    }
    if (!image.length) {
      errs.push({ type: 'media', msg: 'Item media missing' });
    }
    if (versions.length) {
      versions.map((ver) => {
        if (!ver?.link) {
          errs.push({
            type: 'content',
            msg: `${platform === 'Notion' ? 'Duplicate' : 'Remix'} link for ${
              ver?.title
            } variation missing`,
          });
        }
      });
    }

    if (errs.length) {
      setErrMsgs(errs);
      setAddingProduct(false);
      return;
    }

    //to see if quill editor is empty
    var regex = /(<([^>]+)>)/gi;
    const hasText = !!productContent.replace(regex, '').length;
    const hasInfo = !!info.replace(regex, '').length;

    try {
      if (image.length) {
        const imageToUpload = new FormData();
        for (var x = 0; x < image.length; x++) {
          imageToUpload.append('productImages', image[x]);
        }
        const imageDataReq = await uploadImageRequest.post(
          '/products/imageupload',
          imageToUpload
        );
        // coverPicUrl = imageDataReq.data[0].url;
        // coverPicKey = imageDataReq.data[0].key;

        if (files.length) {
          const filesToUpload = new FormData();
          for (var x = 0; x < files.length; x++) {
            filesToUpload.append('productImages', files[x]);
          }
          const filesUploadReq = await uploadImageRequest.post(
            '/products/filesupload',
            filesToUpload
          );
          uploadedFiles = filesUploadReq.data;
        }

        const addDigitalProductReq = await addDigitalProduct({
          title,
          description,
          price,
          coverImage: imageDataReq?.data,
          files: uploadedFiles,
          storeId: currentUser?.store?._id,
          published: published,
          digitalType,
          link: link,
          callToAction: Object.keys(callToAction).length
            ? callToAction.value
            : 'buy',
          payChoice: payChoice,
          suggestedPrice: suggestedPrice ? suggestedPrice : price,
          content: hasText ? productContent : '',
          info: hasInfo ? info : '',
          url: url,
          free: free,
          marketplace: marketplace,
          categories: category,
          dupLink: dupLink,
          platform: platform,
          versions: versions,
          refund: refund,
          reTitle: reTitle,
          reText: reText,
        }).unwrap();

        if (addDigitalProductReq.msg === 'Product added') {
          currentUser.store = addDigitalProductReq.store;
          const newUser = JSON.stringify(currentUser);
          Cookies.set('currentUser', newUser, { sameSite: 'Lax' });
          dispatch(showNotification('Item created'));
          setAddingProduct(false);
          navigate('/dashboard/items');
        } else if (addDigitalProductReq.msg === 'Url taken') {
          setUrlTaken(true);
          setAddingProduct(false);
          return;
        }
      }
    } catch (err) {
      console.log(err);
      setError('There was a server error');
      setAddingProduct(false);
    }
  };

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) => err.msg !== 'Item title missing'
    );
    setErrMsgs(updatedErrMsgs);
  }, [title]);

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) => err.msg !== 'Item URL missing'
    );
    setErrMsgs(updatedErrMsgs);
    setUrlTaken(false);
  }, [url]);

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) =>
        err.msg !==
        `${
          platform === 'Notion'
            ? 'Duplicate link missing'
            : 'Remix link missing'
        }`
    );
    setErrMsgs(updatedErrMsgs);
  }, [dupLink]);

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) => err.msg !== 'Item media missing'
    );
    setErrMsgs(updatedErrMsgs);
  }, [image]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Customize the warning message
      const warningMessage =
        'You have unsaved changes. Are you sure you want to leave?';

      // Modern browsers require a return value to trigger the confirmation dialog
      event.preventDefault();
      event.returnValue = warningMessage;

      return warningMessage;
    };

    // Add event listener for the 'beforeunload' event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate]);

  const styles = isMobile
    ? 'w-full mx-auto h-fit bg-gray-50 p-2 mt-16'
    : 'w-full mx-auto h-screen bg-white ml-2';

  return (
    <>
      <Navbar />
      <div className="flex mx-auto max-w-6xl">
        <SideNavbar />
        <div className={styles}>
          {currentUser?.setup ? (
            <>
              {isMobile ? (
                <MobileForm
                  handleAddProduct={handleAddProduct}
                  handleCancel={handleCancel}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  setImage={setImage}
                  setFiles={setFiles}
                  setPrice={setPrice}
                  price={price}
                  setPublished={setPublished}
                  published={published}
                  setDigitalType={setDigitalType}
                  productContent={productContent}
                  handleProductContent={handleProductContent}
                  setCallToAction={setCallToAction}
                  callToAction={callToAction}
                  payChoice={payChoice}
                  setPayChoice={setPayChoice}
                  setSuggestedPrice={setSuggestedPrice}
                  setProductContent={setProductContent}
                  info={info}
                  setInfo={setInfo}
                  error={error}
                  description={description}
                  title={title}
                  setUrl={setUrl}
                  url={url}
                  addingProduct={addingProduct}
                  setFree={setFree}
                  free={free}
                  marketplace={marketplace}
                  setMarketplace={setMarketplace}
                  handleAction={handleAction}
                  handleType={handleType}
                  handleCategory={handleCategory}
                />
              ) : (
                <DesktopForm
                  handleAddProduct={handleAddProduct}
                  handleCancel={handleCancel}
                  setTitle={setTitle}
                  title={title}
                  setDescription={setDescription}
                  setImage={setImage}
                  image={image}
                  setFiles={setFiles}
                  files={files}
                  setPrice={setPrice}
                  price={price}
                  setPublished={setPublished}
                  published={published}
                  setDigitalType={setDigitalType}
                  digitalType={digitalType}
                  setLink={setLink}
                  productContent={productContent}
                  setProductContent={setProductContent}
                  handleProductContent={handleProductContent}
                  info={info}
                  setInfo={setInfo}
                  setCallToAction={setCallToAction}
                  callToAction={callToAction}
                  payChoice={payChoice}
                  setPayChoice={setPayChoice}
                  setSuggestedPrice={setSuggestedPrice}
                  suggestedPrice={suggestedPrice}
                  error={error}
                  description={description}
                  url={url}
                  setUrl={setUrl}
                  addingProduct={addingProduct}
                  setFree={setFree}
                  free={free}
                  marketplace={marketplace}
                  setMarketplace={setMarketplace}
                  handleAction={handleAction}
                  handleType={handleType}
                  handleCategory={handleCategory}
                  category={category}
                  emptyFields={emptyFields}
                  dupLink={dupLink}
                  setDupLink={setDupLink}
                  currentUser={currentUser}
                  handlePlatform={handlePlatform}
                  platform={platform}
                  add={add}
                  setAdd={setAdd}
                  handleAddVariation={handleAddVariation}
                  handleRemoveVariation={handleRemoveVariation}
                  setLinkForIndex={setLinkForIndex}
                  setVersionPrice={setVersionPrice}
                  setVersionTitle={setVersionTitle}
                  setVersionSum={setVersionSum}
                  versions={versions}
                  urlTaken={urlTaken}
                  errMsgs={errMsgs}
                  refund={refund}
                  setRefund={setRefund}
                  reTitle={reTitle}
                  setReTitle={setReTitle}
                  reText={reText}
                  setReText={setReText}
                />
              )}
            </>
          ) : (
            <div
              className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
              style={{ height: '600px' }}
            >
              <p className="text-stone-800 text-sm">
                Creating item not available
              </p>
              <p className="text-stone-600 text-xs w-72 text-center">
                You need to finish your account setup in settings before
                creating an item.
              </p>

              <Link
                to="/settings"
                className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
              >
                <Settings size={14} />
                <p className="text-xs">Settings</p>
              </Link>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewItem;
