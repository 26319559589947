import React, { useEffect } from 'react';
import {
  useGetCoverImageQuery,
  useDeleteItemImageMutation,
} from '../../api/productsApiSlice';
import { Trash, X } from 'react-feather';

//filepond
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Spinner } from 'flowbite-react';

const CoverImage = ({ productId, product, image, setImage, setCic }) => {
  const {
    data: coverImage,
    isLoading,
    isSuccess,
    refetch,
  } = useGetCoverImageQuery(productId);
  const [deleteItemImage, deleteResult] = useDeleteItemImageMutation(); //for deleting cover image

  const handleDeleteCoverImage = async ({ productId, imgId, key }) => {
    try {
      const deleteImgReq = await deleteItemImage({
        productId,
        imgId,
        key,
      }).unwrap();
      if (deleteImgReq?.msg === 'Image deleted') {
        setCic(deleteImgReq?.coverImageCount);
        refetch();
      } else {
        return;
      }
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  let content;

  if (isLoading) {
    content = (
      <div className="w-full flex items-center justify-center h-44">
        <Spinner />
      </div>
    );
  } else if (isSuccess) {
    content = coverImage.length ? (
      <>
        <div className="grid grid-cols-5 gap-2 mb-1 mt-1">
          {coverImage.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image?.url}
                alt={`Image ${index + 1}`}
                className="w-full h-28 object-fit rounded-md"
              />
              <button
                type="button"
                onClick={(e) =>
                  handleDeleteCoverImage({
                    productId: product._id,
                    imgId: '',
                    key: image?.key,
                  })
                }
                className="absolute top-1 right-1 bg-white text-red-400 font-bold px-1 py-1 rounded-full"
              >
                <X size={14} />
              </button>
            </div>
          ))}
        </div>
        {coverImage.length < 5 ? (
          <>
            <FilePond
              files={image}
              // imageResizeTargetWidth={200}
              allowReorder
              name="productImages"
              // onupdatefiles={(file) => setImage(file)}
              onupdatefiles={(fileItems) => {
                setImage(fileItems.map((fileItem) => fileItem.file));
              }}
              onreorderfiles={(fileItems) => {
                setImage(fileItems.map((fileItem) => fileItem.file));
              }}
              allowMultiple
              instantUpload={false}
              maxFiles={5 - coverImage.length}
              labelIdle="Drag & drop or browse and reorder your images here"
            />
          </>
        ) : (
          ''
        )}
        <div className="w-full flex justify-between">
          <p className="text-stone-600 text-xs">
            {coverImage.length + image.length}/5
          </p>
          <p className="text-stone-600 text-xs">Recommended size 1280x720</p>
        </div>
      </>
    ) : (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <p className="text-sm text-stone-800 text-center">Upload Images</p>
          <p className="text-stone-600 text-center text-xs">
            Click and drag to reorder images
          </p>
        </div>
        <FilePond
          files={image}
          // imageResizeTargetWidth={200}
          allowReorder
          name="productImages"
          onupdatefiles={(fileItems) => {
            setImage(fileItems.map((fileItem) => fileItem.file));
          }}
          onreorderfiles={(fileItems) => {
            setImage(fileItems.map((fileItem) => fileItem.file));
          }}
          allowMultiple
          instantUpload={false}
          maxFiles={5 - coverImage.length}
          labelIdle="Drag & drop or browse and reorder your images here"
        />
        <div className="w-full flex justify-between">
          <p className="text-stone-600 text-xs">{image.length}/5</p>
          <p className="text-stone-600 text-xs">Recommended size 1280x720</p>
        </div>
      </div>
    );
  }

  return content;
};

export default CoverImage;
