import React from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const BackBtn = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route in history
  };

  return (
    <button
      type="button"
      onClick={handleGoBack}
      className="flex items-center text-xs text-stone-600"
    >
      Back
      <ChevronRight size={16} />
    </button>
  );
};

export default BackBtn;
