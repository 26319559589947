import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { isMobile } from 'react-device-detect';

//mui
import { Timeline } from 'flowbite-react';

const Pricing = () => {
  //auto scrolls the page to the top
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Navbar />
      {isMobile ? (
        <div className="h-fit flex items-center justify-center mx-auto mt-24 w-full p-4">
          <div className="flex flex-col items-center gap-10 w-full">
            <div className="flex flex-col items-center text-center gap-2">
              <p className="text-md text-stone-800">Pricing</p>
              <p className="text-xs text-stone-600">
                Our goal is to help creators earn as much as possible. We want
                to offer and maintain a pricing structure that is lower than
                other platforms available and is easy to understand.
              </p>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-full p-2 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                <p className="text-xs text-stone-600 flex items-center">
                  No monthly fees, just a small amount taken from each sale
                </p>
              </div>
              <div className="flex items-center w-full gap-2">
                <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 w-3/6">
                  <p className="font-bold text-stone-800">$0.25</p>

                  <p className="mt-1 text-xs text-stone-600 text-center">
                    fee on sales <span className="font-bold">under $5</span>
                  </p>
                </div>
                <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 w-3/6">
                  <p className="font-bold text-stone-800">$1.00</p>

                  <p className="mt-1 text-xs text-stone-600 text-center">
                    fee on sales <span className="font-bold">$5+</span>
                  </p>
                </div>
              </div>
              <div className="w-full p-2 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                <p className="text-xs text-stone-600 flex items-center">
                  with an additional fee of 2.9% + 30 ¢ / sale by{' '}
                  <span className="underline underline-offset-2 ml-1">
                    <a
                      href="https://stripe.com/pricing"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {' '}
                      Stripe
                    </a>
                  </span>{' '}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-full">
              <p className="text-xs text-stone-800">International Fee</p>
              <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-2 w-full">
                <p className="font-bold text-stone-800">+ 1.5%</p>

                <p className="mt-1 text-xs text-stone-600 text-center">
                  Payments that come from outside the U.S.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-full">
              <p className="text-xs text-stone-800">Example Transactions</p>

              <div className="w-full flex flex-col items-center gap-2">
                <div className="flex flex-col bg-gray-50 items-start w-full gap-2 border border-gray-200 rounded-md p-2">
                  <p className="text-xs text-stone-600">Transaction #1</p>
                  <Timeline>
                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Total
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $4.00
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Customer makes a purchase
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Fruntt
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.25
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Fee we take for a sale under $5
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Stripe
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.42
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Processing fee from Stripe
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Payout
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $3.33
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            What you earn from the sale
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  </Timeline>
                </div>

                <div className="flex flex-col bg-gray-50 items-start w-full gap-2 border border-gray-200 rounded-md p-2">
                  <p className="text-xs text-stone-600">Transaction #2</p>
                  <Timeline>
                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Total
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $14.00
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Customer makes a purchase
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Fruntt
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$1.00
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Fee we take for a sale $5 or over
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Stripe
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.71
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Processing fee from Stripe
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Payout
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $12.29
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            What you earn from the sale
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  </Timeline>
                </div>

                <div className="flex flex-col bg-gray-50 items-start w-full gap-2 border border-gray-200 rounded-md p-2">
                  <p className="text-xs text-stone-600">Transaction #3</p>
                  <Timeline>
                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Total
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $14.00
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Customer makes a purchase
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Fruntt
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$1.00
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Fee we take for a sale $5 or over
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Intl
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.21
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Fee from payment outside the U.S.
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Stripe
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.71
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            Processing fee from Stripe
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Payout
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $12.08
                        </Timeline.Title>
                        <Timeline.Body>
                          <p className="text-xs text-stone-600">
                            What you earn from the sale
                          </p>
                        </Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  </Timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-fit flex items-center justify-center mx-auto mt-32">
          <div
            className="flex flex-col items-center gap-10"
            style={{ width: '500px' }}
          >
            <div className="flex flex-col items-center text-center w-full gap-2">
              <p className="text-md text-stone-800">Pricing</p>
              <p className="text-xs text-stone-600">
                Our goal is to help creators earn as much as possible. We want
                to offer and maintain a pricing structure that is lower than
                other platforms available and is easy to understand.
              </p>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-full p-2  bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                <p className="text-xs text-stone-600 flex items-center">
                  No monthly fees, just a small amount taken from each sale
                </p>
              </div>
              <div className="flex items-center w-full gap-2">
                <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 w-3/6">
                  <p className="font-bold text-stone-800">$0.25</p>

                  <p className="mt-1 text-xs text-stone-600 text-center">
                    fee on sales <span className="font-bold">under $5</span>
                  </p>
                </div>
                <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 w-3/6">
                  <p className="font-bold text-stone-800">$1.00</p>

                  <p className="mt-1 text-xs text-stone-600 text-center">
                    fee on sales <span className="font-bold">$5+</span>
                  </p>
                </div>
              </div>
              <div className="w-full p-2 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                <p className="text-xs text-stone-600 flex items-center">
                  with an additional fee of 2.9% + 30 ¢ / sale by{' '}
                  <span className="underline underline-offset-2 ml-1">
                    <a
                      href="https://stripe.com/pricing"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {' '}
                      Stripe
                    </a>
                  </span>{' '}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-full">
              <p className="text-xs text-stone-800">International Fee</p>
              <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-2 w-full">
                <p className="font-bold text-stone-800">+ 1.5%</p>

                <p className="mt-1 text-xs text-stone-600 text-center">
                  Payments that come from outside the U.S.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-full">
              <p className="text-xs text-stone-800">Example Transactions</p>

              <div className="w-full flex flex-col gap-4">
                <div className="flex flex-col items-center gap-2 bg-gray-50 border border-gray-200 rounded-md p-2">
                  <p className="text-xs text-stone-600">Transaction #1</p>
                  <Timeline horizontal>
                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Total
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $4.00
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Fruntt
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.25
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Stripe
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.42
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Payout
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $3.33
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  </Timeline>
                </div>
                <div className="flex flex-col items-center gap-2 bg-gray-50 border border-gray-200 rounded-md p-2">
                  <p className="text-xs text-stone-600">Transaction #2</p>
                  <Timeline horizontal>
                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Total
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $14.00
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Fruntt
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$1.00
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Stripe
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.71
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Payout
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $12.29
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  </Timeline>
                </div>
                <div className="flex flex-col items-center gap-2 bg-gray-50 border border-gray-200 rounded-md p-2">
                  <p className="text-xs text-stone-600">Transaction #3</p>
                  <Timeline horizontal>
                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Total
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $14.00
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Fruntt
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$1.00
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Intl
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.21
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Stripe
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          -$0.71
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>

                    <Timeline.Item>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time className="text-xs text-stone-600">
                          Payout
                        </Timeline.Time>
                        <Timeline.Title className="text-xs">
                          $12.08
                        </Timeline.Title>
                        <Timeline.Body></Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  </Timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Pricing;
