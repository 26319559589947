import React, { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import StripeAmount from '../../../utils/StripeAmount';
import { PayoutDate } from '../../../utils/PayoutDate';
import { Badge } from 'flowbite-react';
import { useMarkPayAsSeenMutation } from '../../../api/payoutsApiSlice';
import Cookies from 'js-cookie';

const Desktop = ({ payout, currentUser, refetch }) => {
  const [markPayAsSeen, result] = useMarkPayAsSeenMutation();

  useEffect(() => {
    const markSeen = async () => {
      const seenReq = await markPayAsSeen({ payoutId: payout?._id }).unwrap();

      if (seenReq.msg === 'Payout seen') {
        currentUser.newPayouts = seenReq.newPayouts;
        const updatedUser = JSON.stringify(currentUser);
        Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
        refetch();
      }
    };

    if (!payout?.seen) {
      markSeen();
    }
  }, []);

  const DisplayBadge = ({ status }) => {
    switch (status) {
      case 'paid':
        return (
          <Badge color="success" size="xs">
            Paid
          </Badge>
        );
      case 'failed':
        return (
          <Badge color="failure" size="xs">
            Failed
          </Badge>
        );
      case 'cancelled':
        return (
          <Badge color="failure" size="xs">
            Cancelled
          </Badge>
        );
      case 'pending':
        return (
          <Badge color="warning" size="xs">
            Pending
          </Badge>
        );
      case 'in_transit':
        return (
          <Badge color="warning" size="xs">
            In Transit
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col w-96 gap-2 mx-auto">
      <div className="flex w-full justify-between items-end mx-auto">
        <div className="flex flex-col border border-gray-200 rounded-md p-2">
          <p className="text-sm text-stone-800">Payout: {payout?._id}</p>
          <p className="text-xs text-stone-600">
            Arriving <PayoutDate payoutDate={payout?.arrivalDate} />
          </p>
        </div>

        <Link
          to="/dashboard/payouts"
          className="flex items-center text-xs text-stone-600"
        >
          Back
          <ChevronRight size={16} />
        </Link>
      </div>
      <div className="w-full border border-gray-200 rounded-md mx-auto p-2 relative">
        <div className="flex flex-col absolute bottom-0 right-0 mr-2 mb-2">
          <p className="text-sm text-stone-800">
            <DisplayBadge status={payout?.status} />
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col items-start gap-2 w-full">
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Paid To</p>
              <p className="text-xs text-stone-800">{currentUser?.name}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Bank Name</p>
              <p className="text-xs text-stone-800">{payout?.bankName}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Stripe ID</p>
              <p className="text-xs text-stone-800">{payout?.payoutId}</p>
            </div>

            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Currency</p>
              <p className="text-xs text-stone-800">
                {`${payout?.currency.toUpperCase()}`}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Amount</p>
              <button
                disabled
                className="p-2 bg-gray-200 text-stone-800 text-xs font-bold rounded-md"
              >
                <StripeAmount
                  amount={payout?.amount}
                  currency={payout?.currency}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
