import React, { useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useGetProductQuery } from '../../../api/productsApiSlice';
import Footer from '../../../components/Footer/Footer';
import Navbar from '../../../components/Navbar/Navbar';
import { isMobile } from 'react-device-detect';
import EditDigital from './EditDigital';
import Spinner from '../../../components/Spinner';
import SideNavbar from '../../../components/SideNavbar';

const EditItem = () => {
  const { productId } = useParams();

  const {
    data: product,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetProductQuery({
    productId,
  });

  useEffect(() => {
    refetch();
  }, []);

  let content;
  if (isLoading || isFetching) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = (
      <div className="w-full">
        <EditDigital
          product={product}
          productId={productId}
          refetch={refetch}
        />
      </div>
    );
  }

  const styles = isMobile
    ? 'w-full mx-auto h-screen p-2 overflow-y-scroll bg-gray-50'
    : 'w-full mx-auto h-screen bg-white ml-2';

  return (
    <>
      <Navbar />
      <div className="flex mx-auto max-w-6xl">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default EditItem;
