export const minPayouts = [
  { country: 'Albania', code: 'AL', amount: '3000 ALL' },
  { country: 'Algeria', code: 'DZ', amount: '1 DZD' },
  { country: 'Angola', code: 'AO', amount: '15000 AOA' },
  { country: 'Antigua & Barbuda', code: 'AG', amount: '1 XCD' },
  { country: 'Argentina', code: 'AR', amount: '4600 ARS' },
  { country: 'Armenia', code: 'AM', amount: '12100 AMD' },
  { country: 'Australia', code: 'AU', amount: '0 AUD' },
  { country: 'Austria', code: 'AT', amount: '1 EUR' },
  { country: 'Azerbaijan', code: 'AZ', amount: '50 AZN' },
  { country: 'Bahamas', code: 'BS', amount: '1 BSD' },
  { country: 'Bahrain', code: 'BH', amount: '1 BHD' },
  { country: 'Bangladesh', code: 'BD', amount: '20 BDT' },
  { country: 'Belgium', code: 'BE', amount: '1 EUR' },
  { country: 'Benin', code: 'BJ', amount: '1 XOF' },
  { country: 'Bhutan', code: 'BT', amount: '2500 BTN' },
  { country: 'Bolivia', code: 'BO', amount: '200 BOB' },
  {
    country: 'Bosnia & Herzegovina',
    code: 'BA',
    amount: '50 BAM',
  },
  { country: 'Botswana', code: 'BW', amount: '1 BWP' },
  { country: 'Brunei', code: 'BN', amount: '1 BND' },
  { country: 'Bulgaria', code: 'BG', amount: '1 BGN' },
  { country: 'Cambodia', code: 'KH', amount: '123000 KHR' },
  { country: 'Canada', code: 'CA', amount: '0 CAD' },
  { country: 'Chile', code: 'CL', amount: '23000 CLP' },
  { country: 'Colombia', code: 'CO', amount: '140000 COP' },
  { country: 'Costa Rica', code: 'CR', amount: '0 CRC' },
  { country: 'Croatia', code: 'HR', amount: '1 EUR' },
  { country: 'Cyprus', code: 'CY', amount: '1 EUR' },
  { country: 'Czech Republic', code: 'CZ', amount: '1 EUR' },
  { country: 'Côte d’Ivoire', code: 'CI', amount: '1 XOF' },
  { country: 'Denmark', code: 'DK', amount: '20 DKK' },
  { country: 'Dominican Republic', code: 'DO', amount: '1 DOP' },
  { country: 'Ecuador', code: 'EC', amount: '0 USD' },
  { country: 'Egypt', code: 'EG', amount: '20 EGP' },
  { country: 'El Salvador', code: 'SV', amount: '30 USD' },
  { country: 'Estonia', code: 'EE', amount: '1 EUR' },
  { country: 'Ethiopia', code: 'ET', amount: '1 ETB' },
  { country: 'Finland', code: 'FI', amount: '1 EUR' },
  { country: 'France', code: 'FR', amount: '1 EUR' },
  { country: 'Gabon', code: 'GA', amount: '100 XAF' },
  { country: 'Gambia', code: 'GM', amount: '1900 GMD' },
  { country: 'Germany', code: 'DE', amount: '1 EUR' },
  { country: 'Ghana', code: 'GH', amount: '1 GHS' },
  { country: 'Greece', code: 'GR', amount: '1 EUR' },
  { country: 'Guatemala', code: 'GT', amount: '1 GTQ' },
  { country: 'Guyana', code: 'GY', amount: '6300 GYD' },
  { country: 'Hong Kong', code: 'HK', amount: '0 HKD' },
  { country: 'Hungary', code: 'HU', amount: '360 HUF' },
  { country: 'Iceland', code: 'IS', amount: '1 EUR' },
  { country: 'India', code: 'IN', amount: '1 INR' },
  { country: 'Indonesia', code: 'ID', amount: '0 IDR' },
  { country: 'Ireland', code: 'IE', amount: '1 EUR' },
  { country: 'Israel', code: 'IL', amount: '0 ILS' },
  { country: 'Italy', code: 'IT', amount: '1 EUR' },
  { country: 'Jamaica', code: 'JM', amount: '1 JMD' },
  { country: 'Japan', code: 'JP', amount: '1000 JPY' },
  { country: 'Jordan', code: 'JO', amount: '1 JOD' },
  { country: 'Kazakhstan', code: 'KZ', amount: '1 KZT' },
  { country: 'Kenya', code: 'KE', amount: '1 KES' },
  { country: 'Kuwait', code: 'KW', amount: '1 KWD' },
  { country: 'Laos', code: 'LA', amount: '516000 LAK' },
  { country: 'Latvia', code: 'LV', amount: '1 EUR' },
  { country: 'Liechtenstein', code: 'LI', amount: '1 EUR' },
  { country: 'Lithuania', code: 'LT', amount: '1 EUR' },
  { country: 'Luxembourg', code: 'LU', amount: '1 EUR' },
  { country: 'Macao SAR China', code: 'MO', amount: '1 MOP' },
  { country: 'Madagascar', code: 'MG', amount: '132300 MGA' },
  { country: 'Malaysia', code: 'MY', amount: '133 MYR' },
  { country: 'Malta', code: 'MT', amount: '1 EUR' },
  { country: 'Mauritius', code: 'MU', amount: '1 MUR' },
  { country: 'Mexico', code: 'MX', amount: '10 MXN' },
  { country: 'Moldova', code: 'MD', amount: '500 MDL' },
  { country: 'Monaco', code: 'MC', amount: '1 EUR' },
  { country: 'Mongolia', code: 'MN', amount: '105000 MNT' },
  { country: 'Morocco', code: 'MA', amount: '0 MAD' },
  { country: 'Mozambique', code: 'MZ', amount: '1700 MZN' },
  { country: 'Namibia', code: 'NA', amount: '550 NAD' },
  { country: 'Netherlands', code: 'NL', amount: '1 EUR' },
  { country: 'New Zealand', code: 'NZ', amount: '0 NZD' },
  { country: 'Niger', code: 'NE', amount: '1 XOF' },
  { country: 'Nigeria', code: 'NG', amount: '1 NGN' },
  { country: 'North Macedonia', code: 'MK', amount: '1500 MKD' },
  { country: 'Norway', code: 'NO', amount: '20 NOK' },
  { country: 'Oman', code: 'OM', amount: '1 OMR' },
  { country: 'Pakistan', code: 'PK', amount: '1 PKR' },
  { country: 'Panama', code: 'PA', amount: '50 USD' },
  { country: 'Paraguay', code: 'PY', amount: '210000 PYG' },
  { country: 'Peru', code: 'PE', amount: '0 PEN' },
  { country: 'Philippines', code: 'PH', amount: '20 PHP' },
  { country: 'Poland', code: 'PL', amount: '5 PLN' },
  { country: 'Portugal', code: 'PT', amount: '1 EUR' },
  { country: 'Qatar', code: 'QA', amount: '1 QAR' },
  { country: 'Romania', code: 'RO', amount: '5 RON' },
  { country: 'Rwanda', code: 'RW', amount: '100 RWF' },
  { country: 'San Marino', code: 'SM', amount: '1 EUR' },
  { country: 'Saudi Arabia', code: 'SA', amount: '1 SAR' },
  { country: 'Senegal', code: 'SN', amount: '1 XOF' },
  { country: 'Serbia', code: 'RS', amount: '3000 RSD' },
  { country: 'Singapore', code: 'SG', amount: '1 SGD' },
  { country: 'Slovakia', code: 'SK', amount: '1 EUR' },
  { country: 'Slovenia', code: 'SI', amount: '1 EUR' },
  { country: 'South Africa', code: 'ZA', amount: '100 ZAR' },
  { country: 'South Korea', code: 'KR', amount: '40000 KRW' },
  { country: 'Spain', code: 'ES', amount: '1 EUR' },
  { country: 'Sri Lanka', code: 'LK', amount: '1 LKR' },
  { country: 'St. Lucia', code: 'LC', amount: '1 XCD' },
  { country: 'Sweden', code: 'SE', amount: '20 SEK' },
  { country: 'Switzerland', code: 'CH', amount: '1 EUR' },
  { country: 'Taiwan', code: 'TW', amount: '800 TWD' },
  { country: 'Tanzania', code: 'TZ', amount: '1 TZS' },
  { country: 'Thailand', code: 'TH', amount: '600 THB' },
  { country: 'Trinidad & Tobago', code: 'TT', amount: '0 TTD' },
  { country: 'Tunisia', code: 'TN', amount: '0 TND' },
  { country: 'Turkey', code: 'TR', amount: '5 TRY' },
  { country: 'United Arab Emirates', code: 'AE', amount: '5 AED' },
  { country: 'United Kingdom', code: 'GB', amount: '1 GBP' },
  { country: 'United States', code: 'US', amount: '1 USD' },
  { country: 'Uruguay', code: 'UY', amount: '0 UYU' },
  { country: 'Uzbekistan', code: 'UZ', amount: '343000 UZS' },
  { country: 'Vietnam', code: 'VN', amount: '1 VND' },
];
