import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Cookies from 'js-cookie';
import Hero from '../../components/Landing/Hero';
import { useGetFeaturedProductsQuery } from '../../api/productsApiSlice';

const Landing = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const {
    data: products,
    isLoading: gettingProducts,
    isSuccess: gotProducts,
  } = useGetFeaturedProductsQuery();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  if (currentUser) return <Navigate to="/dashboard" />;

  return (
    <div className="h-fit mx-auto">
      <Navbar />

      <div className="mx-auto max-w-6xl flex flex-col">
        <Hero
          products={products}
          gettingProducts={gettingProducts}
          gotProducts={gotProducts}
        />
      </div>

      <Footer />
    </div>
  );
};

export default Landing;
