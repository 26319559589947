import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  ChevronDown,
  ChevronLeft,
  ChevronUp,
  CreditCard,
  File,
  Link,
  List,
  Mail,
  Percent,
  Repeat,
  Tag,
} from 'react-feather';
import { Link as RLink } from 'react-router-dom';

const Features = ({ setDocsView, currentUser }) => {
  const style = currentUser
    ? 'h-screen mx-auto max-w-2xl flex flex-col items-start gap-4'
    : 'h-screen mx-auto max-w-2xl flex flex-col items-center mt-24 gap-4';

  const [view, setView] = useState('');
  const handleView = (newView) => {
    if (newView === view) {
      setView('');
    } else {
      setView(newView);
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return isMobile ? (
    <div className="h-fit mx-auto w-full p-4 flex flex-col items-start mt-14 gap-4">
      <div className="flex flex-col items-end w-full gap-4">
        <div className="flex flex-col items-start w-full border border-gray-200 p-2 bg-gray-50 rounded-md">
          <p className="text-sm text-stone-800">Features</p>
          <p className="text-xs text-stone-600">
            Checkout all the features we currently offer below. Click on a
            feature to read more about it.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2 mt-1"
          >
            <ChevronLeft size={14} />
            Docs
          </button>
        </div>
      </div>
      <div className="flex flex-col items-start gap-4 w-full">
        <button
          type="button"
          onClick={() => handleView('pay')}
          className="w-full flex flex-col flex-grow bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <CreditCard size={18} />
              <p className="text-xs text-stone-800">Secure Payments</p>
            </div>
            {view === 'pay' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'pay' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-left text-stone-600 mt-4">
              All transactions are securely processed through Stripe, ensuring
              user safety. We never store sensitive payment information, keeping
              your data completely safe.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('mor')}
          className="w-full flex flex-col flex-grow bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <File size={18} />
              <p className="text-xs text-stone-800">MoR</p>
            </div>
            {view === 'mor' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'mor' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-left text-stone-600 mt-4">
              We act as your Merchant of Record, serving as the legal
              representative for all transactions. We handle any complications,
              including tax collection, chargebacks, and refunds.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('tax')}
          className="w-full flex flex-col flex-grow bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Percent size={18} />
              <p className="text-xs text-stone-800">Tax Calculation</p>
            </div>
            {view === 'tax' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'tax' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-left text-stone-600 mt-4">
              Automatic tax calculation and collection on all orders. Tax is
              calculated during checkout using the customer's country and postal
              code before payment is collected.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('var')}
          className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <List size={18} />
              <p className="text-xs text-stone-800">Item Variations</p>
            </div>
            {view === 'var' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'var' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-stone-600 mt-4">
              Add variations to your items to offer different versions and
              prices of the same item. This can be used to create a better
              customer experience. Examples: Pro, Basic, etc.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('dis')}
          className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Tag size={18} />
              <p className="text-xs text-stone-800">Item Discounts</p>
            </div>
            {view === 'dis' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'dis' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-stone-600 mt-4">
              Easily create and offer discounts on your items to attract more
              customers. Create discounts as a fixed price or a percentage and
              set use limits, expiration dates and more.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('ref')}
          className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Repeat size={18} />
              <p className="text-xs text-stone-800">Refund Policies</p>
            </div>
            {view === 'ref' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'ref' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-stone-600 mt-4">
              Easily define refund policies on your items so customers know how
              and when to request a refund. Policies can be seen from checkout
              page as a customer is buying an item.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('del')}
          className="w-full flex flex-col bg-gray-50 text-left border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Mail size={18} />
              <p className="text-xs text-stone-800">Delivery</p>
            </div>
            {view === 'del' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'del' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-stone-600 mt-4">
              All orders and project links are automatically delivered after a
              purchase. Customers receive an email with all order details and a
              secure link to open and view the order and purchased project link
              in the browser.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('lin')}
          className="w-full flex bg-gray-50 flex-col text-left border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Link size={18} />
              <p className="text-xs text-stone-800">Duplicate & Remix Links</p>
            </div>
            {view === 'lin' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
              view === 'lin' ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <p className="text-xs text-stone-600 mt-4">
              When it comes to selling project links, we support the sale of
              Duplicate Links and Remix Links. Sell a Duplicate Link for access
              to a Notion project and a Remix Link for access to a Framer
              project.
            </p>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleView('way')}
          className="w-full flex bg-gray-50 flex-col text-left border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center">
              <p className="text-xs text-stone-800">What's on the way?</p>
            </div>
            {view === 'way' ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </div>

          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden flex flex-col gap-3 ${
              view === 'way' ? 'h-auto' : 'max-h-0'
            }`}
          >
            <div className="flex flex-col items-start mt-4">
              <p className="text-xs text-stone-800">Multiple Currencies</p>
              <p className="text-xs text-stone-600">
                List your items and view your dashboard in the currency of your
                choice
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Upsells</p>
              <p className="text-xs text-stone-600">
                Suggest other items to customers making a purchase
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Apple & Google Payments</p>
              <p className="text-xs text-stone-600">
                Pay for orders using your Apple or Google wallets
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Marketplace</p>
              <p className="text-xs text-stone-600">
                A place to browse all items from all creators
              </p>
            </div>

            <p className="text-xs text-stone-800 flex items-center gap-1 mt-2">
              Request a feature{' '}
              <RLink
                to="/contact"
                state={{ data: { value: 'feature', label: 'Feature request' } }}
                className="font-bold"
              >
                here
              </RLink>
            </p>
          </div>
        </button>
      </div>
    </div>
  ) : (
    <div className={style}>
      <div className="flex justify-between items-end w-full">
        <div className="flex flex-col items-start w-full border border-gray-200 p-2 rounded-md bg-gray-50">
          <p className="text-sm text-stone-800">Features</p>
          <p className="text-xs text-stone-600">
            Checkout all the features we currently offer below. Click on a
            feature to read more about it.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center  gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2 mt-1"
          >
            <ChevronLeft size={14} />
            Docs
          </button>
        </div>
      </div>
      <div className="w-full flex items-start gap-2">
        <div className="flex flex-col items-start gap-4 w-full">
          <button
            type="button"
            onClick={() => handleView('pay')}
            className="w-full flex flex-col flex-grow bg-gray-50 border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <CreditCard size={18} />
                <p className="text-xs text-stone-800">Secure Payments</p>
              </div>
              {view === 'pay' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'pay' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-left text-stone-600 mt-4">
                All transactions are securely processed through Stripe, ensuring
                user safety. We never store sensitive payment information,
                keeping your data completely safe.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('mor')}
            className="w-full flex flex-col flex-grow bg-gray-50 border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <File size={18} />
                <p className="text-xs text-stone-800">MoR</p>
              </div>
              {view === 'mor' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'mor' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-left text-stone-600 mt-4">
                We act as your Merchant of Record, serving as the legal
                representative for all transactions. We handle any
                complications, including tax collection, chargebacks, and
                refunds.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('tax')}
            className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Percent size={18} />
                <p className="text-xs text-stone-800">Tax Calculation</p>
              </div>
              {view === 'tax' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'tax' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-stone-600 mt-4">
                Automatic tax calculation and collection on all orders. Tax is
                calculated during checkout using the customer's country and
                postal code before payment is collected.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('var')}
            className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <List size={18} />
                <p className="text-xs text-stone-800">Item Variations</p>
              </div>
              {view === 'var' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'var' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-stone-600 mt-4">
                Add variations to your items to offer different versions and
                prices of the same item. This can be used to create a better
                customer experience. Examples: Pro, Basic, etc.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('dis')}
            className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Tag size={18} />
                <p className="text-xs text-stone-800">Item Discounts</p>
              </div>
              {view === 'dis' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'dis' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-stone-600 mt-4">
                Easily create and offer discounts on your items to attract more
                customers. Create discounts as a fixed price or a percentage and
                set use limits, expiration dates and more.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('ref')}
            className="w-full flex flex-col bg-gray-50 items-start text-left border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Repeat size={18} />
                <p className="text-xs text-stone-800">Refund Policies</p>
              </div>
              {view === 'ref' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'ref' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-stone-600 mt-4">
                Easily define refund policies on your items so customers know
                how and when to request a refund. Policies can be seen from
                checkout page as a customer is buying an item.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('del')}
            className="w-full flex flex-col bg-gray-50 text-left border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Mail size={18} />
                <p className="text-xs text-stone-800">Delivery</p>
              </div>
              {view === 'del' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'del' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-stone-600 mt-4">
                All orders and project links are automatically delivered after a
                purchase. Customers receive an email with all order details and
                a secure link to open and view the order and purchased project
                link in the browser.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleView('lin')}
            className="w-full flex bg-gray-50 flex-col text-left border border-gray-200 rounded-md p-2"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Link size={18} />
                <p className="text-xs text-stone-800">
                  Duplicate & Remix Links
                </p>
              </div>
              {view === 'lin' ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                view === 'lin' ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <p className="text-xs text-stone-600 mt-4">
                When it comes to selling project links, we support the sale of
                Duplicate Links and Remix Links. Sell a Duplicate Link for
                access to a Notion project and a Remix Link for access to a
                Framer project.
              </p>
            </div>
          </button>
        </div>

        <div className="flex flex-col gap-4 p-2 bg-gray-50 border border-gray-200 rounded-md">
          <p className="text-sm text-stone-800">What's on the way?</p>
          <div className="flex flex-col items-start">
            <p className="text-xs text-stone-800">Multiple Currencies</p>
            <p className="text-xs text-stone-600">
              List your items and view your dashboard in the currency of your
              choice
            </p>
          </div>
          <div className="flex flex-col items-start">
            <p className="text-xs text-stone-800">Upsells</p>
            <p className="text-xs text-stone-600">
              Suggest other items to customers making a purchase
            </p>
          </div>
          <div className="flex flex-col items-start">
            <p className="text-xs text-stone-800">Apple & Google Payments</p>
            <p className="text-xs text-stone-600">
              Pay for orders using your Apple or Google wallets
            </p>
          </div>
          <div className="flex flex-col items-start">
            <p className="text-xs text-stone-800">Marketplace</p>
            <p className="text-xs text-stone-600">
              A place to browse all items from all creators
            </p>
          </div>

          <p className="text-xs text-stone-800 flex items-center gap-1">
            Request a feature{' '}
            <RLink
              to="/contact"
              state={{ data: { value: 'feature', label: 'Feature request' } }}
              className="font-bold"
            >
              here
            </RLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
