import { Rating } from '@mui/material';
import { Dropdown } from 'flowbite-react';
import React, { useState } from 'react';
import { ChevronRight, MoreVertical, ShoppingCart, User } from 'react-feather';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import BackBtn from '../../../components/BackBtn';

const Desktop = ({ reviews, productId }) => {
  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  const endOffset = itemOffset + itemsPerPage;

  const currentReviews = reviews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviews.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reviews.length;

    setItemOffset(newOffset);

    window.scroll(0, 0);
  };
  //end of pagination stuff

  return (
    <div className="flex flex-col gap-2 mx-auto w-96">
      <div className="w-full flex justify-between items-end">
        <div className="flex flex-col bg-white rounded-md border border-gray-200 p-2">
          <p className="text-sm text-stone-800">{reviews.length} Reviews</p>
          <p className="text-xs text-stone-600">Item: {productId}</p>
        </div>

        <BackBtn />
      </div>
      {reviews.length > 0 ? (
        currentReviews.map((review) => (
          <div className="w-full flex flex-col gap-2 border border-gray-200 rounded-md p-2 relative">
            <div className="absolute top-0 right-0 mt-1">
              <Dropdown
                label=""
                dismissOnClick={true}
                renderTrigger={() => (
                  <MoreVertical
                    size={14}
                    className="mt-2 mr-2 hover:cursor-pointer"
                  />
                )}
                className=" w-36"
              >
                <Link to={`/dashboard/orders/${review?.orderId}`}>
                  <Dropdown.Item
                    as="div"
                    className="text-xs text-stone-800 flex items-center justify-between w-full"
                  >
                    View order <ShoppingCart size={14} />
                  </Dropdown.Item>
                </Link>
                <Link to={`/dashboard/customers/${review?.customerId}`}>
                  <Dropdown.Item
                    as="div"
                    className="text-xs text-stone-800 flex items-center justify-between w-full"
                  >
                    View customer <User size={14} />
                  </Dropdown.Item>
                </Link>
              </Dropdown>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-xs text-stone-800">{review?.name}</p>

              <Rating
                value={review?.rating}
                precision={0.5}
                size="small"
                readOnly
              />
            </div>

            {review?.review ? (
              <p className="text-xs text-stone-600">{review?.review}</p>
            ) : (
              <div className="w-full bg-gray-50 rounded-md h-12 flex items-center justify-center">
                <p className="text-xs text-stone-800">No written review</p>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="w-full border border-gray-200 rounded-md h-72 flex items-center justify-center">
          <p className="text-xs text-stone-600">
            This item has not been reviewed
          </p>
        </div>
      )}
      {reviews.length > 5 ? (
        <div className="w-full flex justify-end mx-auto">
          <div className="">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              marginPagesDisplayed={0}
              pageRangeDisplayed={0}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="flex items-center"
              activeLinkClassName="activePage"
              pageLinkClassName="notActivePage"
              breakLinkClassName="breakLink"
              disabledClassName="disabled"
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Desktop;
