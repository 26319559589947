import React from 'react';
import { Link } from 'react-router-dom';
import { HelpCircle, Map, MessageCircle } from 'react-feather';
import { FaXTwitter } from 'react-icons/fa6';

const MobileFooter = () => {
  return (
    <footer className="w-full mt-10 p-4">
      <div className="w-full h-full mb-6 p-2">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1 items-start">
            {/* <Link to='/' className='h-full flex justify-center items-center'>
                <IoStorefrontOutline className='text-stone-800 font-bold text-xl' />
                <p
                  className='text-stone-800 font-black text-md mt-1'
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link> */}
            <Link
              to="/"
              className="h-full flex gap-1 justify-center items-center"
            >
              {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
              <Map size={18} />
              <p
                className="font-black text-stone-800 text-md"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Fruntt
              </p>
            </Link>
          </div>
          <div className="flex items-center gap-4">
            <Link to="/privacy" className="text-stone-600 text-xs">
              Privacy
            </Link>
            <Link to="/tos" className="text-stone-600 text-xs">
              Terms
            </Link>
          </div>
          <div className="flex w-16 justify-between text-4xl">
            <Link to="/contact" className="text-stone-600 text-xs">
              <MessageCircle size={18} className="text-stone-800" />
            </Link>

            <a href="https://twitter.com/fruntt_" target="_blank">
              <FaXTwitter className="text-lg text-stone-800" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MobileFooter;
