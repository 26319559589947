import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  Eye,
  MessageSquare,
  ShoppingCart,
  Link as Flink,
  Repeat,
} from 'react-feather';
import { Checkbox, Spinner, Tooltip } from 'flowbite-react';
import { useMarkAllAsSeenMutation } from '../../../api/ordersApiSlice';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../redux/toastSlice';
import io from 'socket.io-client';

const DesktopOrders = ({ orders, refetch, newOrders, currentUser }) => {
  const [viewAll, setViewAll] = useState(false);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOrders = useMemo(() => {
    return orders.filter((order) => {
      const email = order.email ? order.email.toLowerCase() : '';
      const name = order.name ? order.name.toLowerCase() : '';
      const id = order._id ? order._id.toLowerCase() : '';
      const term = searchTerm.toLowerCase();

      return email.includes(term) || name.includes(term) || id.includes(term);
    });
  }, [searchTerm, orders]);

  //stuff for pagination//
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  const endOffset = itemOffset + itemsPerPage;
  const currentOrders = filteredOrders.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredOrders.length;

    setItemOffset(newOffset);
  };
  //pagination stuff ends here//

  const [markAllAsSeen, { isLoading, isSuccess }] = useMarkAllAsSeenMutation();

  const handleViewAllOrders = async () => {
    const unseenOrders = orders.filter((order) => order.seen == false);

    try {
      const req = await markAllAsSeen({ orders: unseenOrders }).unwrap();
      if (req === 'Orders seen') {
        dispatch(showNotification('Orders marked as seen'));
        setViewAll(false);
        refetch();
      }
    } catch (err) {
      console.log(err);
      setViewAll(false);
      return;
    }
  };

  const ElapsedTimeOrDate = ({ orderDate }) => {
    const now = new Date();
    const duration = moment.duration(moment(now).diff(moment(orderDate)));
    const secondsElapsed = duration.asSeconds();
    const minutesElapsed = duration.asMinutes();
    const hoursElapsed = duration.asHours();
    const daysElapsed = duration.asDays();

    let displayText = '';

    if (secondsElapsed < 60) {
      displayText = `${Math.floor(secondsElapsed)} seconds ago`;
    } else if (minutesElapsed < 60) {
      displayText =
        Math.floor(minutesElapsed) === 1
          ? `${Math.floor(minutesElapsed)} minute ago`
          : `${Math.floor(minutesElapsed)} minutes ago`;
    } else if (hoursElapsed < 24) {
      displayText =
        Math.floor(hoursElapsed) === 1
          ? `${Math.floor(hoursElapsed)} hour ago`
          : `${Math.floor(hoursElapsed)} hours ago`;
    } else if (daysElapsed < 2) {
      displayText =
        Math.floor(daysElapsed) === 1
          ? `${Math.floor(daysElapsed)} day ago`
          : `${Math.floor(daysElapsed)} days ago`;
    } else {
      displayText = `${moment(orderDate).format('MMM Do, YYYY')}`;
    }

    return <span>{displayText}</span>;
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WEBSOCK_URL);

    const fetchOrderCount = () => {
      socket.emit('getOrderCount', currentUser?._id);

      socket.on('orderCount', (numberOfOrders) => {
        if (numberOfOrders > 0) {
          refetch();
        }
      });
    };

    fetchOrderCount();

    const interval = setInterval(fetchOrderCount, 60000);

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  return orders.length > 0 ? (
    <div className="flex flex-col gap-2">
      <div className="w-full flex justify-between items-end">
        <div className="flex items-end gap-1">
          <div className="flex flex-col border border-gray-200 rounded-md p-2">
            <p className="text-sm text-stone-800">Orders</p>
            <p className="text-xs text-stone-600">View all incoming orders</p>
          </div>
        </div>

        <div className="flex items-center gap-2">
          {newOrders > 0 && (
            <div className="flex items-center">
              {viewAll ? (
                <div className="flex items-center gap-1">
                  <button
                    type="button"
                    className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                    onClick={() => setViewAll(!viewAll)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                    onClick={handleViewAllOrders}
                  >
                    Mark orders as seen
                  </button>
                </div>
              ) : (
                <div className="w-full flex items-center gap-2">
                  <Checkbox onChange={() => setViewAll(true)} />
                  <div className="flex flex-col items-start">
                    <p className="text-xs text-stone-600">Mark as seen</p>
                  </div>
                </div>
              )}
            </div>
          )}

          <input
            type="text"
            placeholder="Order ID, name, or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 rounded-md w-52 text-xs"
          />
        </div>
        <p className="text-stone-600 font-medium text-xs">
          {filteredOrders.length == 1
            ? `1 order`
            : `${filteredOrders.length} orders`}
        </p>
      </div>

      <div className="flex flex-col gap-2">
        {currentOrders.length ? (
          <>
            {currentOrders.map((order, index) => (
              <Link to={`/dashboard/orders/${order?._id}`} key={index}>
                <div
                  className={`flex items-center justify-between w-full border border-gray-200 hover:bg-gray-50 ${
                    order?.seen ? '' : 'bg-gray-50'
                  } rounded-md p-2`}
                >
                  <div className="flex w-3/12 justify-start mr-4">
                    <p className="text-xs text-stone-800">{order?._id}</p>
                  </div>

                  <div className="flex w-4/12 justify-center">
                    <p className="text-xs text-stone-800">{order?.name}</p>
                  </div>

                  <div className="flex w-3/12 justify-center">
                    <p className="text-xs text-stone-800">
                      <ElapsedTimeOrDate orderDate={order?.placedOn} />
                    </p>
                  </div>

                  <div className="flex w-2/12 justify-center">
                    <div className="flex items-center gap-5 w-24">
                      {order?.viewed && (
                        <Tooltip
                          content={
                            <p className="text-xs text-stone-800">
                              Project link viewed
                            </p>
                          }
                          style="light"
                          arrow={false}
                        >
                          <Eye size={14} className="text-stone-800" />
                        </Tooltip>
                      )}
                      {order?.reviewed && (
                        <Tooltip
                          content={
                            <p className="text-xs text-stone-800">
                              Customer reviewed
                            </p>
                          }
                          style="light"
                          arrow={false}
                        >
                          <MessageSquare size={14} className="text-stone-800" />
                        </Tooltip>
                      )}
                      {order?.refunded && (
                        <Tooltip
                          content={
                            <p className="text-xs text-stone-800">
                              Order refunded
                            </p>
                          }
                          style="light"
                          arrow={false}
                        >
                          <Repeat size={14} className="text-stone-800" />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-grow justify-end w-52">
                    <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center text-xs">
                      <p className="text-xs text-stone-800 font-bold">
                        $
                        {order?.total?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </>
        ) : (
          <div className="w-full h-96 flex items-center justify-center border border-gray-200 rounded-md">
            <p className="text-xs text-stone-600 text-center">
              No orders found
            </p>
          </div>
        )}
      </div>
      {orders.length > 12 ? (
        <div className="w-full flex justify-end mx-auto mt-2">
          <div className="">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              marginPagesDisplayed={0}
              pageRangeDisplayed={0}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              previousLabel="Prev"
              className="flex items-center"
              activeLinkClassName="activePage"
              pageLinkClassName="notActivePage"
              breakLinkClassName="breakLink"
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div
      className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
      style={{ height: '600px' }}
    >
      <ShoppingCart size={18} className="text-stone-800" />
      <p className="text-stone-800 text-sm">No orders</p>

      <p className="text-stone-600 text-xs w-60 text-center">
        View all incoming orders and issue refunds here
      </p>
    </div>
  );
};

export default DesktopOrders;
