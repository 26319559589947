import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiOutlineLink,
  AiOutlineEdit,
} from 'react-icons/ai';
import { FaXTwitter } from 'react-icons/fa6';
import { Instagram, Youtube, Link as FLink, Settings } from 'react-feather';
import { FaMediumM, FaTiktok } from 'react-icons/fa';

//mui
import Avatar from '@mui/material/Avatar';
import { Tooltip } from 'flowbite-react';

const SellerProfile = ({ user }) => {
  return (
    <div className="border border-gray-200 rounded-md w-full p-2 flex flex-col gap-2">
      <div className="flex justify-between items-center w-full">
        <p className="text-xs text-stone-600">Profile</p>
      </div>

      {user?.setup ? (
        <div className="flex items-center justify-between h-full w-full pr-2 pl-2">
          <Avatar
            sx={{ width: 36, height: 36 }}
            src={user?.sellerProfile?.picture?.url}
          />

          <div className="flex flex-col items-start w-60">
            <p className="text-sm text-stone-800">{user?.name}</p>

            <p className="text-stone-600 text-xs">{user?.sellerProfile?.bio}</p>
          </div>
          <Tooltip
            content={
              <p className="text-xs text-stone-800">
                Total sales since you signed up and started selling
              </p>
            }
            style="light"
            className="w-52"
            arrow={false}
          >
            <div className="flex flex-col items-center">
              <p className="text-stone-800 text-lg hover:cursor-default">
                {user?.sellerProfile?.numberOfSales}
              </p>

              <p className="text-stone-600 text-xs">
                {user?.sellerProfile?.numberOfSales == 1
                  ? 'total sale'
                  : 'total sales'}
              </p>
            </div>
          </Tooltip>
          <div className="flex items-center gap-4">
            {user?.sellerProfile?.twitter ? (
              <a href={user?.sellerProfile?.twitter} target="_blank">
                <FaXTwitter className="text-stone-800 text-md" />
              </a>
            ) : (
              <FaXTwitter className="text-gray-200 text-md" />
            )}

            {user?.sellerProfile?.instagram ? (
              <a href={user?.sellerProfile?.instagram} target="_blank">
                <Instagram size={17} className="text-stone-800" />
              </a>
            ) : (
              <Instagram size={17} className="text-gray-200" />
            )}

            {user?.sellerProfile?.youtube ? (
              <a href={user?.sellerProfile?.youtube} target="_blank">
                <Youtube size={17} className="text-stone-800" />
              </a>
            ) : (
              <Youtube size={17} className="text-gray-200" />
            )}

            {user?.sellerProfile?.medium ? (
              <a href={user?.sellerProfile?.medium} target="_blank">
                <FaMediumM className="text-stone-800 text-md" />
              </a>
            ) : (
              <FaMediumM className="text-gray-200 text-md" />
            )}

            {user?.sellerProfile?.link ? (
              <a href={user?.sellerProfile?.tiktok} target="_blank">
                <FLink size={17} className="text-stone-800" />
              </a>
            ) : (
              <FLink size={17} className="text-gray-200" />
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-2">
          <p className="text-stone-800 text-xs">
            You need to finish your account setup in settings
          </p>
          <Link
            to="/settings"
            className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
          >
            <Settings size={14} />
            <p className="text-xs">Settings</p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default SellerProfile;
