import React, { useState } from 'react';
import { useUpdateNotificationsMutation } from '../../api/authApiSlice';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/toastSlice';
import { Checkbox as FlowCheck, Spinner, ToggleSwitch } from 'flowbite-react';
import { Edit } from 'react-feather';
import { Switch } from 'antd';

const Notifications = ({ user, refetch, isFetching, setActiveTabIndex }) => {
  const dispatch = useDispatch();

  const [sendUpdates, setSendUpdates] = useState(user?.sendUpdates);
  const [sendOrderPlaced, setSendOrderPlaced] = useState(user?.sendOrderPlaced);
  const [sendReviewCollected, setSendReviewCollected] = useState(
    user?.sendReviewCollected
  );
  const [sendNewCustomer, setSendNewCustomer] = useState(user?.sendNewCustomer);

  const [sendPayouts, setSendPayouts] = useState(user?.sendPayouts);
  const [edit, setEdit] = useState(false);

  const [updateNotifications, result] = useUpdateNotificationsMutation();

  const handleSaveNotifications = async (e) => {
    e.preventDefault();
    try {
      const updateNotificationsReq = await updateNotifications({
        sendUpdates,
        sendOrderPlaced,
        sendReviewCollected,
        sendNewCustomer,
        sendPayouts,
      }).unwrap();
      if (updateNotificationsReq === 'User updated') {
        refetch();
        setEdit(false);
        dispatch(showNotification('Notifications updated'));
      }
    } catch (err) {
      console.log('there was an error');
    }
  };

  return isFetching ? (
    <div className="w-full h-72 flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="w-full border border-gray-200 rounded-md p-4 flex flex-col">
      {edit ? (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-stone-800">Edit notifications</p>
              <p className="text-xs text-stone-600">
                Set email notifications you want to receive about your account
              </p>
            </div>

            <div className="flex items-center gap-2">
              <button
                type="button"
                className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={(e) => setEdit(!edit)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={handleSaveNotifications}
              >
                Save
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-6 mt-4">
            <div className="flex items-start gap-2">
              {/* <FlowCheck
                checked={sendUpdates}
                onChange={(e) => setSendUpdates(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              /> */}
              <Switch
                size="small"
                checked={sendUpdates}
                onChange={(checked) => setSendUpdates(checked)}
                style={{
                  backgroundColor: sendUpdates
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <div className="flex flex-col">
                <p className="text-xs text-stone-800">News & updates</p>
                <p className="text-xs text-stone-600">
                  Receive emails about Fruntt news and updates
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              {/* <FlowCheck
                checked={sendOrderPlaced}
                onChange={(e) => setSendOrderPlaced(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              /> */}
              <Switch
                size="small"
                checked={sendOrderPlaced}
                onChange={(checked) => setSendOrderPlaced(checked)}
                style={{
                  backgroundColor: sendOrderPlaced
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <div className="flex flex-col items-start">
                <p className="text-xs text-stone-800">New orders</p>
                <p className="text-xs text-stone-600">
                  Receive an email everytime a new order comes in
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              {/* <FlowCheck
                checked={sendPayouts}
                onChange={(e) => setSendPayouts(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              /> */}
              <Switch
                size="small"
                checked={sendPayouts}
                onChange={(checked) => setSendPayouts(checked)}
                style={{
                  backgroundColor: sendPayouts
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <div className="flex flex-col items-start">
                <p className="text-xs text-stone-800">Payout received</p>
                <p className="text-xs text-stone-600">
                  Receive an email whenever you receive a payout
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              {/* <FlowCheck
                checked={sendNewCustomer}
                onChange={(e) => setSendNewCustomer(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              /> */}
              <Switch
                size="small"
                checked={sendNewCustomer}
                onChange={(checked) => setSendNewCustomer(checked)}
                style={{
                  backgroundColor: sendNewCustomer
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <div className="flex flex-col items-start">
                <p className="text-xs text-stone-800">New customers</p>
                <p className="text-xs text-stone-600">
                  Receive an email everytime a new customer makes a purchase
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              {/* <FlowCheck
                checked={sendReviewCollected}
                onChange={(e) => setSendReviewCollected(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              /> */}
              <Switch
                size="small"
                checked={sendReviewCollected}
                onChange={(checked) => setSendReviewCollected(checked)}
                style={{
                  backgroundColor: sendReviewCollected
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <div className="flex flex-col items-start">
                <p className="text-xs text-stone-800">Review submitted</p>
                <p className="text-xs text-stone-600">
                  Receive an email everytime a customer submits a review
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-stone-800">Notifications</p>
              <p className="text-xs text-stone-600">
                Set email notifications you want to receive about your account
              </p>
            </div>

            <button
              type="button"
              className="text-stone-800"
              onClick={(e) => setEdit(!edit)}
            >
              <Edit size={14} />
            </button>
          </div>
          <div className="flex flex-col gap-6 mt-4">
            <div className="flex items-center gap-2">
              <Switch
                size="small"
                disabled
                checked={user?.sendUpdates}
                style={{
                  backgroundColor: user?.sendUpdates
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <p className="text-xs text-stone-800">News & updates</p>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                size="small"
                disabled
                checked={user?.sendOrderPlaced}
                style={{
                  backgroundColor: user?.sendOrderPlaced
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />

              <p className="text-xs text-stone-800">New orders</p>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                size="small"
                disabled
                checked={user?.sendPayouts}
                style={{
                  backgroundColor: user?.sendPayouts
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />

              <p className="text-xs text-stone-800">Payout received</p>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                size="small"
                disabled
                checked={user?.sendNewCustomer}
                style={{
                  backgroundColor: user?.sendNewCustomer
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <p className="text-xs text-stone-800">New customers</p>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                size="small"
                disabled
                checked={user?.sendReviewCollected}
                style={{
                  backgroundColor: user?.sendReviewCollected
                    ? 'rgb(41 37 36)'
                    : 'rgb(229 231 235)',
                  borderColor: '#000000',
                }}
              />
              <p className="text-xs text-stone-800">Review submitted</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
