import { Avatar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Instagram,
  Lock,
  Youtube,
  Link as FLink,
  ChevronLeft,
  X,
  ChevronRight,
  Filter,
} from 'react-feather';
import { FaMediumM } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import ReactPaginate from 'react-paginate';
import Cookies from 'js-cookie';
import { useAddVisitMutation } from '../../../api/storefrontApiSlice';
import Select from 'react-select';

const MobileStore = ({ storeAndItems }) => {
  const [items, setItems] = useState(storeAndItems?.products);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);

  const [visitAdded, setVisitAdded] = useState(false);
  const visited = Cookies.get(`visited-${storeAndItems?.storefront?.name}`)
    ? Cookies.get(`visited-${storeAndItems?.storefront?.name}`)
    : null;

  //hook for adding a visit
  const [addVisit, { isLoading, isSuccess }] = useAddVisitMutation();

  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 3;

  const endOffset = itemOffset + itemsPerPage;

  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
    window.scroll(0, 0);
  };
  //end of pagination stuff

  const [priceFilter, setPriceFilter] = useState({
    value: 'all',
    label: 'All',
  });
  const priceFilters = [
    { value: 'all', label: 'All' },
    { value: 'paid', label: 'Paid' },
    { value: 'free', label: 'Free' },
  ];

  const [catFilter, setCatFilter] = useState({
    value: 'all',
    label: 'All',
  });
  const [categories, setCategories] = useState([
    { value: 'all', label: 'All' },
    ...storeAndItems?.categories,
  ]);

  const handlePriceFilterChange = (value) => {
    setPriceFilter(value);
    setItemOffset(0);
    setCurrentPage(0);

    if (value.value === 'paid') {
      const paid = storeAndItems?.products.filter((prod) => prod.price > 0);

      if (catFilter.value !== 'all') {
        const catPaid = paid.filter((item) =>
          item.categories.some((category) => category.value === catFilter.value)
        );
        setItems(catPaid);
      } else {
        setItems(paid);
      }
    } else if (value.value === 'free') {
      const free = storeAndItems?.products.filter((prod) => prod.price === 0);

      if (catFilter.value !== 'all') {
        const catFree = free.filter((item) =>
          item.categories.some((category) => category.value === catFilter.value)
        );
        setItems(catFree);
      } else {
        setItems(free);
      }
    } else {
      if (catFilter.value !== 'all') {
        const catAll = storeAndItems?.products.filter((item) =>
          item.categories.some((category) => category.value === catFilter.value)
        );
        setItems(catAll);
      } else {
        setItems(storeAndItems?.products);
      }
    }
  };

  const handleCatFilterChange = (value) => {
    setCatFilter(value);
    setItemOffset(0);
    setCurrentPage(0);

    const filteredItems =
      value.value === 'all'
        ? storeAndItems?.products
        : storeAndItems?.products.filter((item) =>
            item.categories.some((category) => category.value === value.value)
          );

    if (priceFilter.value === 'paid') {
      const paidCat = filteredItems.filter((prod) => prod.price > 0);
      setItems(paidCat);
    } else if (priceFilter.value === 'free') {
      const freeCat = filteredItems.filter((prod) => prod.price === 0);
      setItems(freeCat);
    } else {
      setItems(filteredItems);
    }
  };

  useEffect(() => {
    const visit = async () => {
      const currentDate = new Date();
      try {
        const visitReq = await addVisit({
          storeId: storeAndItems?.storefront?._id,
          visitedOn: currentDate,
        }).unwrap();

        if (visitReq === 'Visit tracked') {
          Cookies.set(`visited-${storeAndItems?.storefront?.name}`, true);
          setVisitAdded(true);
        }
        return;
      } catch (err) {
        return;
      }
    };

    if (!visited && !visitAdded) visit();
  }, []);

  return (
    <div className="w-full flex flex-col items-center gap-2 mb-10 p-4">
      <div className="w-full border border-gray-200 bg-white rounded-md flex flex-col gap-4 p-2">
        <div className="flex items-center gap-3">
          <div className="">
            <Avatar
              src={storeAndItems?.sellerProfile?.profilePic}
              sx={{ width: 32, height: 32 }}
            />
          </div>

          <div className="flex flex-col items-start">
            <p className="text-stone-800 text-sm text-left">
              {storeAndItems?.sellerProfile?.name}
            </p>
            <p className="text-stone-600 text-xs text-left break-all">
              {storeAndItems?.sellerProfile?.bio}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-4">
            {storeAndItems?.sellerProfile?.twitter ? (
              <a href={storeAndItems?.sellerProfile?.twitter} target="_blank">
                <FaXTwitter className="text-stone-800 text-sm" />
              </a>
            ) : (
              ''
            )}

            {storeAndItems?.sellerProfile?.instagram ? (
              <a href={storeAndItems?.sellerProfile?.instagram} target="_blank">
                <Instagram size={16} className="text-stone-800" />
              </a>
            ) : (
              ''
            )}

            {storeAndItems?.sellerProfile?.youtube ? (
              <a href={storeAndItems?.sellerProfile?.youtube} target="_blank">
                <Youtube size={16} className="text-stone-800" />
              </a>
            ) : (
              ''
            )}

            {storeAndItems?.sellerProfile?.medium ? (
              <a href={storeAndItems?.sellerProfile?.medium} target="_blank">
                <FaMediumM className="text-stone-800 text-sm" />
              </a>
            ) : (
              ''
            )}

            {storeAndItems?.sellerProfile?.link ? (
              <a href={storeAndItems?.sellerProfile?.link} target="_blank">
                <FLink size={16} className="text-stone-800" />
              </a>
            ) : (
              ''
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="">
              <p className="text-stone-600 text-xs">
                {storeAndItems?.products.length == 1
                  ? `1 item`
                  : `${storeAndItems?.products.length} items`}
              </p>
            </div>
            <div className="">
              <p className="text-stone-600 text-xs">
                {storeAndItems?.sellerProfile?.numberOfSales === 1
                  ? `1 sale`
                  : `${storeAndItems?.sellerProfile?.numberOfSales} sales`}
              </p>
            </div>
          </div>
        </div>
      </div>

      {storeAndItems?.products.length &&
      storeAndItems?.storefront?.allowFiltering ? (
        <div className="w-full mx-auto">
          {open ? (
            <div className="flex items-end gap-2 w-full">
              <div className="flex flex-col items-start w-5/12">
                <p className="text-xs text-stone-600">Price</p>
                <Select
                  options={priceFilters}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  isSearchable={false}
                  onChange={handlePriceFilterChange}
                  value={priceFilter}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: 'rgb(229 231 235)',
                      backgroundColor: '#ffffff',
                      borderWidth: 1,
                      '&:hover': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      '&:focus': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      fontSize: '12px',
                      borderRadius: '.375rem',
                      boxShadow: 'none',
                      zIndex: 99999,
                      position: 'relative',
                      height: 35,
                      minHeight: 35,
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: 35,
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 999,
                      fontSize: '12px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? 'rgb(229 231 235)'
                        : state.isFocused
                        ? 'rgb(249 250 251)'
                        : '',
                      color: 'black',
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <div className="flex flex-col items-start w-5/12">
                <p className="text-xs text-stone-600">Categories</p>
                <Select
                  options={categories}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  isSearchable={false}
                  onChange={handleCatFilterChange}
                  value={catFilter}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: 'rgb(229 231 235)',
                      backgroundColor: '#ffffff',
                      borderWidth: 1,
                      '&:hover': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      '&:focus': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      fontSize: '12px',
                      borderRadius: '.375rem',
                      boxShadow: 'none',
                      zIndex: 99999,
                      position: 'relative',
                      height: 35,
                      minHeight: 35,
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: 35,
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 999,
                      fontSize: '12px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? 'rgb(229 231 235)'
                        : state.isFocused
                        ? 'rgb(249 250 251)'
                        : '',
                      color: 'black',
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <div className="w-2/12 flex justify-end">
                <button
                  type="button"
                  onClick={() => setOpen(!open)}
                  className=""
                >
                  <X size={16} className="text-stone-600" />
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-end">
              <button
                type="button"
                onClick={() => setOpen(!open)}
                className="flex items-center text-stone-600"
              >
                <ChevronLeft size={14} />
                <Filter size={16} />

                {/*  <p className="text-stone-600 text-sm">Filter</p> */}
              </button>
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      <div className="w-full flex flex-col">
        {items.length > 0 ? (
          <>
            <div className="w-full flex flex-col gap-4">
              {currentItems.map((product, index) => (
                <Link to={`/${product?.pageName}/${product?.url}`} key={index}>
                  <div className="border border-gray-200 rounded-md bg-white relative flex flex-col gap-2 hover:bg-gray-50 p-2">
                    <div className="w-full h-full relative">
                      <img
                        src={product?.coverImages[0]?.url}
                        className="rounded-md w-full h-32 object-cover"
                      />
                      <div className="absolute top-0 right-0 bg-gray-100/80 mt-1 mr-1 rounded-md p-1 pl-2 pr-2">
                        <p className="text-xs text-stone-800 font-bold">
                          {product?.free
                            ? 'FREE'
                            : product?.payChoice
                            ? `$${product?.price?.toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}+`
                            : `$${product?.price?.toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}`}
                        </p>
                      </div>
                    </div>

                    <div className="w-full flex flex-col gap-2 items-start">
                      <p className="text-stone-800 text-sm font-bold">
                        {product?.title}
                      </p>
                      <p className="text-xs text-stone-600">
                        {product?.description}
                      </p>
                      {product?.categories?.length ? (
                        <div className="flex flex-wrap gap-1 mt-2">
                          {product?.categories.map((cat) => (
                            <span className="bg-gray-200 text-stone-800 text-xs p-2 pt-0.5 pb-0.5 rounded-md">
                              {cat?.label}
                            </span>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            {storeAndItems?.products.length > 3 ? (
              <div className="w-full flex justify-end mt-2">
                <div className="">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={0} // Set to 0 to hide margin pages
                    pageRangeDisplayed={0}
                    pageCount={pageCount}
                    forcePage={currentPage}
                    previousLabel="Prev"
                    renderOnZeroPageCount={null}
                    className="flex items-center"
                    activeLinkClassName="activePage"
                    pageLinkClassName="notActivePage"
                    breakLinkClassName="breakLink"
                    disabledClassName="disabled"
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <div className="w-full bg-white h-96 border border-gray-200 rounded-md flex items-center justify-center">
            <div className="flex flex-col items-center">
              <p className="text-stone-600 text-xs">No items</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileStore;
