import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetPayoutQuery } from '../../../api/payoutsApiSlice';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import { Spinner } from 'flowbite-react';
import Desktop from './Desktop';
import Cookies from 'js-cookie';
import SideNavbar from '../../../components/SideNavbar';

const PayoutDetail = () => {
  const { payoutId } = useParams();

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const {
    data: payout,
    isLoading,
    isSuccess,
    refetch,
  } = useGetPayoutQuery({ payoutId });

  let content;

  if (isLoading) {
    content = (
      <div className="w-full mx-auto flex items-center justify-center h-96">
        <Spinner />
      </div>
    );
  } else if (isSuccess) {
    content = (
      <Desktop payout={payout} currentUser={currentUser} refetch={refetch} />
    );
  }

  return (
    <>
      <Navbar />
      <div className="flex max-w-6xl mx-auto">
        <SideNavbar />
        <div className="w-full mx-auto h-screen bg-white ml-2">{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default PayoutDetail;
