import { Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Check, ChevronLeft, X } from 'react-feather';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import {
  useConfirmPaymentMutation,
  useUpdateOrderMutation,
} from '../../api/ordersApiSlice';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import PayForm from './PayForm';
import Select from 'react-select';
import { availCountries } from '../../utils/availCountries';
import { postcodeValidator } from 'postcode-validator';
import { isMobile } from 'react-device-detect';
import MobileCheckoutForm from './MobileCheckoutForm';
import Modal from 'react-modal';
import moment from 'moment';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

const CheckoutForm = ({
  orderId,
  total,
  item,
  handleResetCheckout,
  setError,
  versionId,
  disCode,
  varTitle,
  handleApplyDiscount,
  setDisCode,
  disApplied,
  disErr,
  applyingDis,
  discount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [email, setEmail] = useState(currentUser?.email || '');
  const [name, setName] = useState('');
  const [zip, setZip] = useState('');
  const [tax, setTax] = useState(0);
  const [taxType, setTaxType] = useState('');
  const [country, setCountry] = useState({});
  const [placingOrder, setPlacingOrder] = useState(false);
  const [updatingOrder, setUpdatingOrder] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [openRe, setOpenRe] = useState(false);

  const [updateOrder, { isLoading }] = useUpdateOrderMutation();
  const [confirmPayment, { isLoading: paying }] = useConfirmPaymentMutation();

  const handleCountry = (value) => {
    setCountry(value);
  };

  const itemPrice = versionId
    ? item?.versions?.find((version) => version._id === versionId)?.price // Find the matching version price
    : item?.price;

  // Helper function to validate the postal code
  const validatePostalCode = (zip, countryCode) => {
    try {
      const validPostal = postcodeValidator(zip, countryCode);

      if (!validPostal) {
        // Postal code is invalid
        return false;
      }

      return true; // Valid postal code
    } catch (error) {
      // Tag the error to indicate it comes from postcodeValidator
      error.source = 'postcodeValidator';
      throw error;
    }
  };

  const handleUpdateOrder = async (e) => {
    e.preventDefault();
    setUpdatingOrder(true);

    if (
      !email.trim() ||
      !name.trim() ||
      !zip.trim() ||
      !Object.keys(country).length
    ) {
      setError('Enter all customer details');
      setUpdatingOrder(false);
      return;
    }

    try {
      const clientCurrentDate = new Date();

      const isPostalValid = validatePostalCode(zip, country?.value);
      if (!isPostalValid) {
        setError('Invalid postal code');
        setUpdatingOrder(false);
        return; // Exit early if the postal code is invalid
      }

      //update order with data
      const updatedOrderReq = await updateOrder({
        orderId: orderId,
        email: email.toLowerCase(),
        name: name,
        country: country,
        zipcode: zip,
        total: total,
        item: item,
        versionId: versionId,
        disCode: disCode,
        userId:
          currentUser && currentUser?.email === email ? currentUser?._id : '',
        placedOn: clientCurrentDate.toISOString(),
      }).unwrap();
      if (updatedOrderReq.msg === 'Order updated') {
        setTax(updatedOrderReq?.formattedTax);
        setTaxType(updatedOrderReq?.order?.taxType);
        setPaymentReady(true);
        setUpdatingOrder(false);
      }
    } catch (error) {
      setUpdatingOrder(false);
      if (error.source === 'postcodeValidator') {
        setError('Invalid postal code');
      } else {
        setError('There was a server error');
      }
      console.log(error);
      return;
    }
  };

  const handleConfirmOrder = async (e) => {
    e.preventDefault();
    setPlacingOrder(true);
    setError('');

    if (!stripe || !elements) {
      setError('There was an error');
      setPlacingOrder(false);
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setError(submitError);
        setPlacingOrder(false);
        return;
      }
      const { error, confirmationToken } = await stripe.createConfirmationToken(
        {
          elements,
          params: {
            payment_method_data: {
              billing_details: {
                name: name,
                email: email,
                phone: '7819566606',
                address: {
                  country: country?.value,
                  postal_code: zip,
                },
              },
            },
          },
        }
      );
      if (error) {
        setError(error);
        setPlacingOrder(false);
        return;
      }

      //confirm payment on order
      const paymentReq = await confirmPayment({
        confirmationToken: confirmationToken?.id,
        paymentOrigin: confirmationToken?.payment_method_preview?.card?.country,
        orderId: orderId,
        disCode: disCode,
      }).unwrap();
      if (paymentReq?.status === 'succeeded') {
        Cookies.remove('orderId');
        setPlacingOrder(false);
        setPaymentReady(false);
        navigate(`/order/${orderId}?oat=${paymentReq?.oat}`);
      } else if (paymentReq?.status === 'failed') {
        setPlacingOrder(false);
        setError(paymentReq?.msg);
        return;
      }
    } catch (err) {
      setPlacingOrder(false);
      setError('There was a server error');
      return;
    }
  };

  useEffect(() => {
    setError('');
  }, [email, name, zip, country, setError]);

  return isMobile ? (
    <MobileCheckoutForm
      paymentReady={paymentReady}
      email={email}
      setEmail={setEmail}
      setName={setName}
      handleCountry={handleCountry}
      setZip={setZip}
      elements={elements}
      stripe={stripe}
      placingOrder={placingOrder}
      handleResetCheckout={handleResetCheckout}
      isLoading={isLoading}
      paying={paying}
      handleConfirmOrder={handleConfirmOrder}
      updatingOrder={updatingOrder}
      handleUpdateOrder={handleUpdateOrder}
      item={item}
      versionId={versionId}
      varTitle={varTitle}
      total={total}
      tax={tax}
      taxType={taxType}
      disApplied={disApplied}
      disCode={disCode}
      disErr={disErr}
      applyingDis={applyingDis}
      handleApplyDiscount={handleApplyDiscount}
      setDisCode={setDisCode}
      discount={discount}
    />
  ) : (
    <div className="w-full flex items-start gap-4 p-4">
      <form className="flex flex-col w-7/12">
        {paymentReady ? (
          ''
        ) : (
          <>
            <p className="text-stone-600 text-xs">Customer</p>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 mt-1">
                <input
                  type="email"
                  placeholder="Email"
                  className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <input
                  type="text"
                  placeholder="Name"
                  className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="flex items-center gap-2">
                <Select
                  options={availCountries}
                  onChange={handleCountry}
                  className="w-full"
                  menuPortalTarget={document.body}
                  placeholder="Country"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: 'rgb(229 231 235)',
                      backgroundColor: 'rgb(249 250 251)',
                      borderWidth: 1,
                      '&:hover': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      '&:focus': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      borderRadius: '.375rem',
                      boxShadow: 'none',
                      zIndex: 0,
                      fontSize: '12px',
                      minHeight: 35,
                      height: 35,
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: 35,
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      fontSize: '12px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? 'rgb(229 231 235)'
                        : state.isFocused
                        ? 'rgb(249 250 251)'
                        : '',
                      color: 'black',
                    }),
                  }}
                />

                <input
                  type="text"
                  placeholder="Postal Code"
                  className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                  onChange={(e) => setZip(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
        {paymentReady ? (
          <>
            <p className="text-stone-600 text-xs mb-1">Payment</p>
            {/* <PaymentElement id="payment-element" /> */}
            <PayForm elements={elements} stripe={stripe} />
          </>
        ) : (
          ''
        )}

        {paymentReady ? (
          <div className="w-full flex items-center mt-2">
            <button
              type="button"
              disabled={placingOrder}
              onClick={handleResetCheckout}
              className="w-3/12 h-10 text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
            >
              <ChevronLeft size={16} />
            </button>
            <button
              disabled={placingOrder || isLoading || paying}
              type="button"
              onClick={handleConfirmOrder}
              className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
            >
              {placingOrder ? <Spinner /> : 'Buy Now'}
            </button>
          </div>
        ) : (
          <div className="w-full flex items-center mt-2">
            <button
              type="button"
              disabled={updatingOrder}
              onClick={handleResetCheckout}
              className="w-3/12 h-10 text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
            >
              <ChevronLeft size={16} />
            </button>
            <button
              disabled={updatingOrder || isLoading || paying}
              type="button"
              onClick={handleUpdateOrder}
              className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
            >
              {updatingOrder ? <Spinner /> : 'Payment'}
            </button>
          </div>
        )}
      </form>
      <div className="w-5/12">
        <p className="text-stone-600 text-xs mb-1">Buying</p>
        <div className="w-full border border-gray-200 rounded-md p-2 flex flex-col gap-1 items-start">
          <p className="text-stone-800 text-sm font-bold">{item?.title}</p>
          <p className="text-stone-600 text-xs">{item?.description}</p>
          {versionId ? (
            <div className="w-full flex flex-col">
              <p className="text-stone-800 text-xs">Variation</p>
              <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">{varTitle}</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="flex flex-col gap-2 w-full mt-1 p-2 bg-gray-50 rounded-md">
            {paymentReady ? (
              <>
                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">Item:</p>
                  <p className="text-xs text-stone-800">
                    {item?.currency?.symbol}
                    {itemPrice?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                {discount?.code ? (
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xs text-stone-800">Discount:</p>
                    <p className="text-xs text-stone-800">
                      -$
                      {(discount.percent
                        ? (itemPrice * discount.amount) / 100 // Calculate percentage discount
                        : discount.amount
                      ) // Use fixed discount amount
                        .toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </p>
                  </div>
                ) : (
                  ''
                )}

                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">
                    {/* {taxType === 'vat' ? 'VAT:' : 'Tax:'} */}
                    Tax/VAT:
                  </p>
                  <p className="text-xs text-stone-800">
                    $
                    {tax?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}

            <div className="flex items-center justify-between w-full">
              <p className="text-xs text-stone-800 font-bold">Total:</p>
              <p className="text-xs text-stone-800">
                {item?.currency?.symbol}
                {(total + tax)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
        </div>
        {item?.discounts && !paymentReady && (
          <div className="flex flex-col gap-2 w-full mt-2">
            {disErr && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{disErr}</p>
              </div>
            )}
            {disApplied ? (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <Check size={16} className="text-green-400" />
                <p className="text-stone-800 text-xs">Discount applied</p>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <input
                  type="text"
                  className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                  placeholder="Enter code"
                  onChange={(e) => setDisCode(e.target.value)}
                />
                <button
                  type="button"
                  onClick={handleApplyDiscount}
                  className="p-2 bg-gray-200 text-stone-800 border border-gray-200 rounded-md text-xs"
                  disabled={applyingDis || disApplied || !disCode}
                >
                  Apply Discount
                </button>
              </div>
            )}
          </div>
        )}
        {item?.refund?.set ? (
          <div className="flex justify-end w-full mt-1">
            <button
              type="button"
              onClick={() => setOpenRe(!openRe)}
              className="text-xs text-stone-600"
            >
              Refund?
            </button>
          </div>
        ) : (
          ''
        )}
        <Modal
          isOpen={openRe}
          onRequestClose={() => setOpenRe(!openRe)}
          style={customStyles}
          contentLabel="Refund Policy Modal"
        >
          <div className="w-96 flex flex-col items-start gap-2">
            <p className="text-stone-600 text-xs">Refund policy</p>

            <div className="flex flex-col gap-1">
              <p className="text-stone-800 text-sm">{item?.refund?.title}</p>
              <p className="text-stone-600 text-xs">{item?.refund?.text}</p>
            </div>

            <div className="w-full flex items-center justify-between">
              <p className="text-stone-800 text-xs">
                Updated on{' '}
                {moment(item?.refund?.updatedOn).format('MMM Do, YYYY')}
              </p>
              <button
                type="button"
                className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={() => setOpenRe(!openRe)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CheckoutForm;
