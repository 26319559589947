import moment from 'moment';
import React, { useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { useMarkCustomerAsSeenMutation } from '../../../api/customersApiSlice';
import Cookies from 'js-cookie';
import BackBtn from '../../../components/BackBtn';

const Desktop = ({ customer, refetch }) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [markCustomerAsSeen, result] = useMarkCustomerAsSeenMutation();

  useEffect(() => {
    const markSeen = async () => {
      const seenReq = await markCustomerAsSeen({
        customerId: customer?._id,
      }).unwrap();

      if (seenReq.msg === 'Customer seen') {
        currentUser.newCustomers = seenReq.newCustomers;
        const updatedUser = JSON.stringify(currentUser);
        Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
        refetch();
      }
    };

    if (!customer?.seen) {
      markSeen();
    }
  }, []);

  return (
    <div className="flex flex-col w-96 gap-2 mx-auto">
      <div className="flex w-full justify-between items-end mx-auto">
        <div className="flex flex-col border border-gray-200 rounded-md p-2">
          <p className="text-sm text-stone-800">Customer: {customer?._id}</p>
          <p className="text-xs text-stone-600">
            {moment(customer?.createdAt).format('MMMM Do, YYYY')}
          </p>
        </div>

        <BackBtn />
      </div>
      <div className="w-full border border-gray-200 rounded-md mx-auto p-2 relative">
        <div className="absolute bottom-0 right-0 mr-2 mb-2">
          <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center">
            <p className="text-xs text-stone-800 font-bold">
              {customer?.numberOfOrders === 1
                ? `${customer?.numberOfOrders} order`
                : `${customer?.numberOfOrders} orders`}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col items-start gap-2 w-full">
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Name</p>
              <p className="text-xs text-stone-800">{customer?.name}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Email</p>
              <p className="text-xs text-stone-800">{customer?.email}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-stone-600 text-xs">Location</p>

              <div className="flex items-start">
                <ReactCountryFlag
                  countryCode={customer?.country?.code}
                  className="mr-1"
                />
                <p className="text-xs text-stone-800 w-72">
                  {customer?.country?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
