import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import SideNavbar from '../../../components/SideNavbar';
import Footer from '../../../components/Footer/Footer';
import Desktop from './Desktop';
import { Spinner } from 'flowbite-react';
import { useGetCustomerQuery } from '../../../api/customersApiSlice';
import { useParams } from 'react-router-dom';

const CustomerDetail = () => {
  const { customerId } = useParams();
  const {
    data: customer,
    isLoading,
    isSuccess,
    refetch,
  } = useGetCustomerQuery({ customerId });

  let content;

  if (isLoading) {
    content = (
      <div className="w-full mx-auto flex items-center justify-center h-96">
        <Spinner />
      </div>
    );
  } else if (isSuccess) {
    content = <Desktop customer={customer} refetch={refetch} />;
  }

  return (
    <>
      <Navbar />
      <div className="flex max-w-6xl mx-auto">
        <SideNavbar />
        <div className="w-full mx-auto h-screen bg-white ml-2">{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default CustomerDetail;
