import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import {
  Bookmark,
  Check,
  ChevronDown,
  ChevronUp,
  Clipboard,
  CreditCard,
  DollarSign,
  ExternalLink,
  Home,
  Layers,
  Layout,
  Plus,
  RotateCcw,
  Settings,
  ShoppingBag,
  ShoppingCart,
  User,
} from 'react-feather';

//flowbite
import { Badge, Sidebar } from 'flowbite-react';

const SideNavbar = () => {
  const [forceUpdate, setForceUpdate] = useState(false);

  //for changing nav links according to the page
  const path = window.location.pathname;
  const activeLink =
    'text-sm h-8 font-medium text-stone-800 w-full rounded-md bg-gray-200 flex items-center pl-2 outline outline-0';
  const notActiveLink =
    'text-sm font-medium w-full h-8 hover:bg-gray-200 rounded-md text-stone-800 flex items-center pl-2 outline outline-0';

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`https://fruntt.com/${currentUser?.store?.name}`)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy!', err);
      });
  };

  const homeIcon = () => {
    return <Home size={18} />;
  };

  const tempIcon = () => {
    return <Layers size={18} />;
  };

  const ordersIcon = () => {
    return <ShoppingCart size={18} />;
  };

  const custIcon = () => {
    return <User size={18} />;
  };

  const payIcon = () => {
    return <CreditCard size={18} />;
  };

  const libIcon = () => {
    return <Bookmark size={18} />;
  };

  const markIcon = () => {
    return <ShoppingBag size={18} />;
  };

  const settingsIcon = () => {
    return <Settings size={18} />;
  };

  const pageIcon = () => {
    return <Layout size={18} />;
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WEBSOCK_URL); // Change URL to your server URL

    const fetchOrderCount = () => {
      socket.emit('getOrderCount', currentUser?._id);
    };

    const fetchPayoutCount = () => {
      socket.emit('getPayoutCount', currentUser?._id);
    };

    const fetchCustomerCount = () => {
      socket.emit('getCustomerCount', currentUser?.storeId);
    };

    socket.on('orderCount', (numberOfOrders) => {
      currentUser.newOrders = numberOfOrders;
      const updatedUser = JSON.stringify(currentUser);
      Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
      setForceUpdate((prevState) => !prevState);
    });

    socket.on('payoutCount', (numberOfPayouts) => {
      currentUser.newPayouts = numberOfPayouts;
      const updatedUser = JSON.stringify(currentUser);
      Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
      setForceUpdate((prevState) => !prevState);
    });

    socket.on('customerCount', (numberOfCustomers) => {
      currentUser.newCustomers = numberOfCustomers;
      const updatedUser = JSON.stringify(currentUser);
      Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
      setForceUpdate((prevState) => !prevState);
    });

    fetchOrderCount();
    fetchPayoutCount();
    fetchCustomerCount();

    const interval = setInterval(fetchOrderCount, 60000); // Fetch order count every minute
    const payInterval = setInterval(fetchPayoutCount, 90000);
    const custInterval = setInterval(fetchCustomerCount, 60000);

    return () => {
      clearInterval(interval);
      clearInterval(payInterval);
      clearInterval(custInterval);
      socket.disconnect();
    };
  }, []);

  return isMobile ? (
    ''
  ) : (
    <Sidebar>
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <Link to="/dashboard">
            <Sidebar.Item
              icon={homeIcon}
              className={path === '/dashboard' ? activeLink : notActiveLink}
              as="div"
            >
              Home
            </Sidebar.Item>
          </Link>
          <Link to="/dashboard/items">
            <Sidebar.Item
              icon={tempIcon}
              className={
                path === '/dashboard/items' ||
                path === '/dashboard/new/item' ||
                path.startsWith('/dashboard/item/edit')
                  ? activeLink
                  : notActiveLink
              }
              style={{ marginTop: '2px' }}
              as="div"
            >
              Items
            </Sidebar.Item>
          </Link>
          <Link to="/dashboard/orders">
            <Sidebar.Item
              icon={ordersIcon}
              label={
                currentUser?.newOrders > 10
                  ? '10+'
                  : currentUser?.newOrders > 0
                  ? currentUser?.newOrders
                  : ''
              }
              className={
                path === '/dashboard/orders' ||
                path === '/dashboard/orders/:orderId'
                  ? activeLink
                  : notActiveLink
              }
              style={{ marginTop: '2px' }}
              as="div"
            >
              Orders
            </Sidebar.Item>
          </Link>
          <Link to="/dashboard/customers">
            <Sidebar.Item
              icon={custIcon}
              label={
                currentUser?.newCustomers > 10
                  ? '10+'
                  : currentUser?.newCustomers > 0
                  ? currentUser?.newCustomers
                  : ''
              }
              className={
                path === '/dashboard/customers' ? activeLink : notActiveLink
              }
              style={{ marginTop: '2px' }}
              as="div"
            >
              Customers
            </Sidebar.Item>
          </Link>
          <Link to="/dashboard/payouts">
            <Sidebar.Item
              icon={payIcon}
              label={
                currentUser?.newPayouts > 10
                  ? '10+'
                  : currentUser?.newPayouts > 0
                  ? currentUser?.newPayouts
                  : ''
              }
              className={
                path === '/dashboard/payouts' ? activeLink : notActiveLink
              }
              style={{ marginTop: '2px' }}
              as="div"
            >
              Payouts
            </Sidebar.Item>
          </Link>
          <Link to="/dashboard/library">
            <Sidebar.Item
              icon={libIcon}
              className={
                path === '/dashboard/library' ? activeLink : notActiveLink
              }
              as="div"
              style={{ marginTop: '2px' }}
            >
              Library
            </Sidebar.Item>
          </Link>
        </Sidebar.ItemGroup>

        <Sidebar.ItemGroup>
          <Sidebar.Collapse
            icon={pageIcon}
            label="Your Page"
            renderChevronIcon={(theme, open) => {
              return open ? <ChevronUp size={14} /> : <ChevronDown size={14} />;
            }}
            className="text-sm font-medium w-full h-8 hover:bg-gray-200 rounded-md text-stone-800 flex items-center pl-2 outline outline-0"
            as="div"
            style={{ marginBottom: '2px' }}
          >
            <Sidebar.Item
              href="#"
              as="div"
              className="text-sm font-medium w-full h-8 hover:bg-gray-200 rounded-md text-stone-800 flex items-center pl-2 outline outline-0"
            >
              <Link
                to={`/${currentUser?.store?.name}`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full text-sm flex items-center justify-between text-stone-800"
              >
                Open Page <ExternalLink size={14} />
              </Link>
            </Sidebar.Item>

            <Sidebar.Item
              href="#"
              as="div"
              className="text-sm font-medium w-full h-8 hover:bg-gray-200 rounded-md text-stone-800 flex items-center pl-2 outline outline-0"
            >
              <button
                type="button"
                onClick={copyToClipboard}
                className="w-full flex items-center justify-between text-sm text-stone-800"
              >
                Share Link <Clipboard size={14} />
              </button>
            </Sidebar.Item>
            {copied && (
              <div className="flex items-center justify-start gap-2 border border-gray-200 rounded-md p-1 pr-2 pl-2">
                <Check size={16} className="text-green-400" />
                <p className="text-stone-800 text-xs">
                  URL copied to clipboard
                </p>
              </div>
            )}
          </Sidebar.Collapse>
          <Link to="/marketplace">
            <Sidebar.Item
              icon={markIcon}
              className={path === '/marketplace' ? activeLink : notActiveLink}
              style={{ marginTop: '2px' }}
              as="div"
            >
              Marketplace
            </Sidebar.Item>
          </Link>
          <Link to="/settings">
            <Sidebar.Item
              icon={settingsIcon}
              className={path === '/settings' ? activeLink : notActiveLink}
              style={{ marginTop: '2px', marginBottom: '4px' }}
              as="div"
            >
              Settings
            </Sidebar.Item>
          </Link>
          <Link to="/dashboard/new/item" className="w-full mt-2">
            <button className="w-full text-stone-800 text-xs bg-gray-200 rounded-md p-1 flex items-center justify-center gap-1">
              Create <Plus size={12} />
            </button>
          </Link>
        </Sidebar.ItemGroup>
      </Sidebar.Items>

      {!currentUser?.stripeOnboard && !currentUser?.bankAdded && (
        <Sidebar.CTA>
          <div className="mb-3 flex items-center">
            <Badge color="failure">Payouts Disabled</Badge>
          </div>
          <div className="text-xs text-stone-800 dark:text-gray-400">
            You need to connect a payout option in{' '}
            <span>
              <Link to="/settings" state={{ index: 3 }} className="font-bold">
                settings
              </Link>
            </span>{' '}
            before you can publish items for sale.
          </div>
        </Sidebar.CTA>
      )}
    </Sidebar>
  );
};

export default SideNavbar;
