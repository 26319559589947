import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Badge } from 'flowbite-react';
import StripeAmount from '../../../utils/StripeAmount';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { CreditCard, Settings } from 'react-feather';
import { PayoutDate } from '../../../utils/PayoutDate';
import { io } from 'socket.io-client';

const Desktop = ({ data, refetch }) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;

  const endOffset = itemOffset + itemsPerPage;

  const currentPayouts = data?.payouts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data?.payouts.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.payouts?.length;
    setItemOffset(newOffset);
  };
  //end of pagination stuff

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WEBSOCK_URL);

    const fetchPayoutCount = () => {
      socket.emit('getPayoutCount', currentUser?._id);

      socket.on('payoutCount', (numberOfPayouts) => {
        if (numberOfPayouts > 0) {
          refetch();
        }
      });
    };

    fetchPayoutCount();

    const interval = setInterval(fetchPayoutCount, 90000);

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  const DisplayBadge = ({ status }) => {
    switch (status) {
      case 'paid':
        return (
          <Badge color="success" size="xs">
            Paid
          </Badge>
        );
      case 'failed':
        return (
          <Badge color="failure" size="xs">
            Failed
          </Badge>
        );
      case 'cancelled':
        return (
          <Badge color="failure" size="xs">
            Cancelled
          </Badge>
        );
      case 'pending':
        return (
          <Badge color="warning" size="xs">
            Pending
          </Badge>
        );
      case 'in_transit':
        return (
          <Badge color="warning" size="xs">
            In Transit
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-end justify-between">
        <div className="flex flex-col items-start border border-gray-200 rounded-md p-2 relative">
          {!currentUser?.bankAdded &&
            !currentUser?.stripeOnboard &&
            !currentUser?.bankPending &&
            !currentUser?.stripePending && (
              <div className="flex flex-col gap-2 items-start">
                <div className="flex flex-col items-start">
                  <p className="text-sm text-stone-800">
                    No payout option connected
                  </p>
                  <p className="text-stone-600 text-xs flex items-center gap-1">
                    You need to connect a payout option in
                    <span>
                      <Link
                        to="/settings"
                        state={{ index: 3 }}
                        className="flex items-center justify-center text-xs text-stone-600 font-bold"
                      >
                        settings
                      </Link>
                    </span>
                  </p>
                </div>
                <Badge color="failure" size="xs">
                  Payouts Disabled
                </Badge>
              </div>
            )}
          {currentUser?.bankPending && (
            <div className="flex flex-col gap-2 items-start">
              <div className="flex flex-col items-start">
                <p className="text-xs text-stone-800">
                  Payouts via bank account
                </p>
                <p className="text-stone-600 text-xs flex items-center gap-1">
                  Continue connecting your bank account in
                  <span>
                    <Link
                      to="/settings"
                      className="flex items-center justify-center text-xs text-stone-600 font-bold"
                    >
                      settings
                    </Link>
                  </span>
                </p>
              </div>
              <Badge color="info" size="xs">
                Bank Pending
              </Badge>
            </div>
          )}
          {currentUser?.stripePending && (
            // <div className="flex flex-col break-words">
            //   <p className="text-xs text-stone-600">
            //     Payouts via Stripe account
            //   </p>
            //   <p className="text-stone-800 text-sm">
            //     Continue connecting your Stripe account in settings
            //   </p>
            // </div>
            <div className="flex flex-col gap-2 items-start">
              <div className="flex flex-col items-start">
                <p className="text-xs text-stone-800">
                  Payouts via Stripe account
                </p>
                <p className="text-stone-600 text-xs flex items-center gap-1">
                  Continue connecting your Stripe account in
                  <span>
                    <Link
                      to="/settings"
                      className="flex items-center justify-center text-xs text-stone-600 font-bold"
                    >
                      settings
                    </Link>
                  </span>
                </p>
              </div>
              <Badge color="info" size="xs">
                Stripe Pending
              </Badge>
            </div>
          )}
          {currentUser?.bankAdded && (
            <div className="flex items-end gap-6">
              <div className="flex flex-col gap-2 items-start">
                <div className="flex flex-col">
                  <p className="text-xs text-stone-600">Payouts via</p>
                  <p className="text-stone-800 text-xs">
                    {currentUser?.bankName}
                  </p>
                </div>
                <Badge color="success" size="xs">
                  Payouts Enabled
                </Badge>
              </div>
              <div className="flex flex-col">
                <p className="text-xs text-stone-800">Balance</p>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-start">
                    <p className="text-stone-800 text-sm">
                      <StripeAmount
                        amount={data?.balance?.available}
                        currency={currentUser?.bankCurrency}
                      />
                    </p>
                    <p className="text-xs text-stone-600">Available</p>
                  </div>
                  <div className="flex flex-col items-start">
                    <p className="text-stone-800 text-sm">
                      <StripeAmount
                        amount={data?.balance?.pending}
                        currency={currentUser?.bankCurrency}
                      />
                    </p>
                    <p className="text-xs text-stone-600">Pending</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentUser?.stripeOnboard && (
            <div className="flex flex-col gap-2 items-start">
              <div className="flex flex-col">
                <p className="text-xs text-stone-600">Payouts via</p>
                <p className="text-stone-800 text-xs">
                  {currentUser?.stripeId}
                </p>
              </div>
              <Badge color="success" size="xs">
                Payouts Enabled
              </Badge>
            </div>
          )}
        </div>
        {data?.payouts.length > 0 && (
          <div className="w-3/12 flex justify-end items-center">
            <div className="flex items-center">
              <p className="text-stone-600 text-xs">
                {data?.payouts.length === 1
                  ? `${data?.payouts.length} payout`
                  : `${data?.payouts.length} payouts`}
              </p>
            </div>
          </div>
        )}
      </div>

      {data?.payouts.length ? (
        <div className="flex flex-col w-full bg-white gap-2">
          {currentPayouts.map((payout, index) => (
            <Link to={`/dashboard/payouts/${payout?._id}`} key={index}>
              <div
                className={`flex justify-between items-center border border-gray-200 ${
                  payout?.seen ? '' : 'bg-gray-50'
                } rounded-md p-2 hover:bg-gray-50`}
              >
                <div className="flex flex-col items-start w-72">
                  <p className="text-stone-800 text-xs">{payout?._id}</p>
                  <p className="text-stone-600 text-xs">
                    Arriving <PayoutDate payoutDate={payout?.arrivalDate} />
                  </p>
                </div>

                <div className="w-44 flex items-start justify-center">
                  <DisplayBadge status={payout?.status} />
                </div>

                <div className="flex justify-end w-72">
                  <button
                    disabled
                    className="pl-2 pr-2 p-1 bg-gray-200 text-stone-800 text-xs font-bold rounded-md"
                  >
                    <StripeAmount
                      amount={payout?.amount}
                      currency={payout?.currency}
                    />
                  </button>
                </div>
              </div>
            </Link>
          ))}
          {data?.payouts.length > 8 && (
            <div className="w-full flex justify-end mt-2">
              <div className="">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={0}
                  pageCount={pageCount}
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  className="flex items-center"
                  activeLinkClassName="activePage"
                  pageLinkClassName="notActivePage"
                  breakLinkClassName="breakLink"
                  disabledClassName="disabled"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex flex-col border-gray-200 items-center justify-center rounded-md w-full border bg-white"
          style={{ height: '510px' }}
        >
          <div className="flex flex-col items-center gap-2">
            <CreditCard size={18} className="text-stone-800" />
            <p className="text-stone-800 text-sm">No payouts</p>
            <p className="text-stone-600 text-xs text-center w-60">
              {currentUser?.bankAdded
                ? 'You will receive payouts when you make sales and accumulate a balance'
                : 'You only see payouts here if your payout option is a bank account'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Desktop;
