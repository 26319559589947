import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { useParams } from 'react-router-dom';
import { useGetProductByUrlQuery } from '../../../api/productsApiSlice';
import Spinner from '../../../components/Spinner';
import ItemNotFound from './ItemNotFound';

const ItemDetail = () => {
  const { pageUrl, productUrl } = useParams();

  const {
    data: product,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useGetProductByUrlQuery({ productUrl: productUrl, pageUrl: pageUrl });

  useEffect(() => {
    refetch();
  }, []);
  let content;

  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = isMobile ? (
      <Mobile
        product={product?.product}
        rating={product?.rating}
        payouts={product?.payouts}
        showSales={product?.showSales}
        reviews={product?.reviews}
      />
    ) : (
      <Desktop
        product={product?.product}
        rating={product?.rating}
        payouts={product?.payouts}
        showSales={product?.showSales}
        reviews={product?.reviews}
      />
    );
  } else if (error?.status === 404) {
    content = <ItemNotFound />;
  }

  const styles = isMobile
    ? 'w-full mx-auto h-screen bg-white'
    : 'w-full mx-auto bg-white';

  return (
    <>
      <div className="flex mx-auto max-w-3xl h-auto">
        <div className={styles}>{content}</div>
      </div>
    </>
  );
};

export default ItemDetail;
