import React, { useEffect } from 'react';
import { Book, ChevronRight } from 'react-feather';
import Demos from './Demos';
import Countries from './Countries';
import Features from './Features';
import Payments from './Payments';

const MobileDocs = ({ demos, docsView, setDocsView }) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      {docsView === 'home' && (
        <div className="h-screen w-full p-4 flex justify-center mx-auto mt-16">
          <div className="w-96 mx-auto flex flex-col gap-2">
            <div className="w-full flex items-end justify-between pb-0">
              <div className="flex flex-col items-start">
                <div className="flex items-center">
                  <Book size={14} className="text-stone-800" />
                  <p className="text-sm text-stone-800">Fruntt Docs</p>
                </div>

                <p className="text-xs text-stone-600">
                  Browse our docs below to learn more about Fruntt
                </p>
              </div>
            </div>
            <button
              type="button"
              onClick={() => setDocsView('countries')}
              className="flex items-center justify-between w-full border border-gray-200 bg-gray-50 rounded-md p-2"
            >
              <div className="flex flex-col items-start w-8/12">
                <p className="text-stone-800 text-xs font-bold">
                  Available Countries
                </p>
                <p className="text-stone-600 text-xs text-left">
                  Find out what countries Fruntt is available in
                </p>
              </div>
              <ChevronRight size={16} className="text-stone-800" />
            </button>
            <button
              type="button"
              onClick={() => setDocsView('features')}
              className="flex items-center justify-between w-full border border-gray-200 bg-gray-50 rounded-md p-2"
            >
              <div className="flex flex-col items-start w-8/12">
                <p className="text-stone-800 text-xs font-bold">Features</p>

                <p className="text-stone-600 text-xs text-left">
                  Checkout all the features we currently offer
                </p>
              </div>
              <ChevronRight size={16} className="text-stone-800" />
            </button>
            <button
              type="button"
              onClick={() => setDocsView('payments')}
              className="flex items-center justify-between bg-gray-50 w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
            >
              <div className="flex flex-col items-start w-8/12">
                <p className="text-stone-800 text-xs font-bold">
                  Payments & Payouts
                </p>

                <p className="text-stone-600 text-xs text-left">
                  Find out everything about accepting payments and being paid
                </p>
              </div>
              <ChevronRight size={16} className="text-stone-800" />
            </button>
            <button
              type="button"
              onClick={() => setDocsView('demos')}
              className="flex items-center justify-between w-full border border-gray-200 bg-gray-50 rounded-md p-2"
            >
              <div className="flex flex-col items-start w-8/12">
                <p className="text-stone-800 text-xs font-bold">Tutorials</p>
                <p className="text-stone-600 text-xs text-left">
                  Watch our quick tutorials on how to use various features in
                  Fruntt
                </p>
              </div>
              <ChevronRight size={16} className="text-stone-800" />
            </button>
            <button
              disabled
              className="h-10 text-xs text-stone-600 w-full border border-gray-200 bg-gray-50 rounded-md p-2"
            >
              More on the way
            </button>
          </div>
        </div>
      )}

      {docsView === 'features' && <Features setDocsView={setDocsView} />}

      {docsView === 'payments' && <Payments setDocsView={setDocsView} />}

      {docsView === 'demos' && (
        <Demos demos={demos} setDocsView={setDocsView} />
      )}

      {docsView === 'countries' && <Countries setDocsView={setDocsView} />}
    </>
  );
};

export default MobileDocs;
