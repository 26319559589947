import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import CoverImage from '../../../components/Items/CoverImage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronUp,
  ExternalLink,
  Info,
  Plus,
  Trash,
  X,
} from 'react-feather';
import Cookies from 'js-cookie';
import Discounts from '../../../components/Items/Discounts';
import { Tooltip, Checkbox, Spinner, Badge } from 'flowbite-react';
import { framerCats, notionCats } from '../../../utils/itemCats';
import moment from 'moment';
import { Switch } from 'antd';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

const EditDesktop = ({
  handleDelete,
  handleSaveEdit,
  handlePlatform,
  platform,
  setTitle,
  title,
  setDescription,
  description,
  price,
  setPrice,
  published,
  setPublished,
  unpublish,
  setUnpublish,
  setFiles,
  files,
  setImage,
  image,
  product,
  digitalType,
  setDigitalType,
  error,
  refetchProduct,
  productContent,
  setProductContent,
  callToAction,
  setCallToAction,
  payChoice,
  setPayChoice,
  suggestedPrice,
  setSuggestedPrice,
  deletingProduct,
  info,
  setInfo,
  url,
  setUrl,
  free,
  category,
  setFree,
  handleType,
  handleAction,
  handleCategory,
  setDupLink,
  dupLink,
  updatingProduct,
  emptyFields,
  inReview,
  versions,
  handleAddVariation,
  handleRemoveVariation,
  add,
  setAdd,
  setLinkForIndex,
  setVersionSum,
  setVersionTitle,
  setVersionPrice,
  urlTaken,
  errMsgs,
  refund,
  setRefund,
  reTitle,
  setReTitle,
  reText,
  setReText,
  reDate,
  setCic,
}) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;
  const [startDelete, setStartDelete] = useState(false);
  const [editDisActive, setEditDisActive] = useState(false);
  const [addContent, setAddContent] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const detailErrs = errMsgs.filter((err) => err.type === 'detail');
  const contentErrs = errMsgs.filter((err) => err.type === 'content');
  const mediaErrs = errMsgs.filter((err) => err.type === 'media');

  const actionOptions = [
    { value: 'buy', label: 'Buy Now' },
    { value: 'want', label: 'I want this!' },
    { value: 'get', label: 'Get Now' },
  ];

  const defaultPlatform = {
    value: platform,
    label: platform,
  };
  const platforms = [
    { value: 'Notion', label: 'Notion' },
    { value: 'Framer', label: 'Framer' },
  ];

  const isToday = moment(product?.updatedAt).isSame(moment(), 'day');

  var regex = /(<([^>]+)>)/gi;
  const hasInfo = !!info.replace(regex, '').length;
  const hasContent = !!productContent.replace(regex, '').length;

  const formattedActionValue = actionOptions.find(
    (option) => option.value === callToAction
  );

  const [addDescription, setAddDescription] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleTabIndex = (index) => {
    setActiveTabIndex(index);
    if (index === 0 || index === 1 || index === 3) {
      setEditDisActive(false);
    }
  };

  const handleFree = () => {
    setFree(!free);
    setPrice(0);
    handleAction({ value: 'get', label: 'Get Now' });
  };

  const handleCancelAddCon = () => {
    setProductContent('');
    setAddContent(false);
  };

  const handleCancelAddDes = () => {
    setInfo('');
    setAddDescription(false);
  };

  return (
    <div className="w-full flex flex-col items-end gap-2">
      <Modal
        isOpen={showInfo}
        onRequestClose={() => setShowInfo(!showInfo)}
        style={customStyles}
        contentLabel="Info Modal"
      >
        <div className="flex flex-col gap-4 w-96">
          <div className="flex flex-col">
            <p className="text-stone-800 text-sm">What is a project link?</p>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <p className="text-xs text-stone-800">Notion</p>
              <p className="text-xs text-stone-600">
                When you create a project in Notion and publish it, you will get
                a Duplicate Link. This is the project link you would sell to
                customers.
              </p>
              <a
                href={`https://www.notion.so/help/public-pages-and-web-publishing`}
                target="_blank"
                className="text-xs text-stone-800 flex gap-1 mt-1"
              >
                {' '}
                Tutorial <ExternalLink size={14} />
              </a>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-xs text-stone-800">Framer</p>
              <p className="text-xs text-stone-600">
                When you create a project in Framer and want to share it, you
                will get a Remix Link. This is the project link you would sell
                to customers.
              </p>
              <a
                href={`https://www.framer.com/help/articles/how-to-create-a-remix-link/`}
                target="_blank"
                className="text-xs text-stone-800 flex gap-1 mt-1"
              >
                {' '}
                Tutorial <ExternalLink size={14} />
              </a>
            </div>
          </div>

          <div className="w-full flex items-center justify-end">
            <button
              type="button"
              className="bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
              onClick={() => setShowInfo(!showInfo)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-between w-full border border-gray-200 rounded-md p-4">
        <div className="flex flex-col items-start">
          <div className="flex items-center gap-1">
            <Tooltip
              content={
                <div className="flex flex-col">
                  <p className="text-xs text-stone-800">Item status</p>
                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-1">
                      <p className="text-xs text-stone-600">Approved:</p>
                      {product?.approved ? (
                        <Check size={16} className="text-green-400" />
                      ) : (
                        <X size={16} className="text-red-400" />
                      )}
                    </div>
                    <div className="flex items-center gap-1">
                      <p className="text-xs text-stone-600">Published:</p>
                      {inReview ? (
                        <Badge color="warning">In Review</Badge>
                      ) : (
                        <>
                          {product?.published ? (
                            <Badge color="success">Published</Badge>
                          ) : (
                            <Badge color="info">Draft</Badge>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {inReview ? (
                    <p className="text-xs text-stone-600 mt-1">
                      Changes can be made while your item is 'In Review'
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              }
              style="light"
              arrow={false}
            >
              <p className="text-sm text-stone-800">
                Editing item: {product?._id}
              </p>
            </Tooltip>
            {product?.published ? (
              <Link
                to={`/${product?.pageName}/${product?.url}`}
                className="text-xs text-stone-800 flex"
                target="_blank"
              >
                <ExternalLink size={14} />
              </Link>
            ) : (
              ''
            )}
          </div>
          <p className="text-xs text-stone-600">
            {isToday
              ? 'Updated today'
              : `Last updated ${moment(product?.updatedAt).format(
                  'MMM Do, YYYY'
                )}`}{' '}
          </p>
        </div>

        {inReview ? (
          ''
        ) : (
          <div className="flex items-center">
            {product?.approved ? (
              <div className="flex items-center gap-2">
                <Switch
                  size="small"
                  checked={published}
                  onChange={(checked) => setPublished(checked)}
                  style={{
                    backgroundColor: published
                      ? 'rgb(41 37 36)'
                      : 'rgb(229 231 235)',
                    borderColor: '#000000',
                  }}
                />
                <div className="flex flex-col">
                  <p className="text-xs text-stone-800">Publish</p>
                  <p className="text-xs text-stone-600">
                    Publish item for sales
                  </p>
                </div>
              </div>
            ) : (
              <Tooltip
                content={
                  <p className="text-xs text-stone-800">
                    Approval is only needed the first time you publish an item.
                    Items are reviewed within 24 hours.
                  </p>
                }
                style="light"
                className="w-72"
                arrow={false}
              >
                <div className="flex items-center gap-2">
                  <Switch
                    size="small"
                    checked={published}
                    onChange={(checked) => setPublished(checked)}
                    style={{
                      backgroundColor: published
                        ? 'rgb(41 37 36)'
                        : 'rgb(229 231 235)',
                      borderColor: '#000000',
                    }}
                  />
                  <div className="flex flex-col">
                    <p className="text-xs text-stone-800">Publish</p>
                    <p className="text-xs text-stone-600">
                      Submit item for approval
                    </p>
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        )}

        <div className="flex items-center gap-2">
          {startDelete ? (
            <>
              <button
                type="button"
                className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={(e) => setStartDelete(!startDelete)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={handleDelete}
                disabled={deletingProduct}
              >
                Delete item
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => setStartDelete(!startDelete)}
              >
                <Trash size={16} className="text-red-400" />
              </button>
              {!product?.coverImages.length ? (
                ''
              ) : (
                <Link
                  to="/dashboard/items"
                  className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                >
                  Cancel
                </Link>
              )}

              {editDisActive ? (
                ''
              ) : (
                <button
                  type="button"
                  className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                  onClick={handleSaveEdit}
                  disabled={updatingProduct}
                >
                  Save
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {error && (
        <div className="border border-gray-200 rounded-md p-2">
          <p className="text-stone-800 text-xs flex items-center gap-1">
            <X size={14} className="text-red-400" />
            {error}
          </p>
        </div>
      )}

      <div className="w-full flex items-start gap-2">
        {errMsgs.length ? (
          <>
            {detailErrs.length ? (
              <div className="flex flex-col border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs flex items-center gap-1">
                  Details
                  <X size={14} className="text-red-400" />
                </p>
                <div className="flex flex-col gap-1">
                  {detailErrs.map((err, index) => (
                    <p className="text-xs text-stone-600" key={index}>
                      {err.msg}
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {mediaErrs.length ? (
              <div className="flex flex-col border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs flex items-center gap-1">
                  Media
                  <X size={14} className="text-red-400" />
                </p>
                <div className="flex flex-col">
                  {mediaErrs.map((err, index) => (
                    <p className="text-xs text-stone-600" key={index}>
                      {err.msg}
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {contentErrs.length ? (
              <div className="flex flex-col border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs flex items-center gap-1">
                  Content
                  <X size={14} className="text-red-400" />
                </p>
                <div className="flex flex-col gap-1">
                  {contentErrs.map((err, index) => (
                    <p className="text-xs text-stone-600" key={index}>
                      {err.msg}
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {urlTaken && (
          <div className="border border-gray-200 rounded-md p-2">
            <p className="text-stone-800 text-xs flex items-center gap-1">
              <X size={14} className="text-red-400" />
              Item URL{' '}
              <span className="font-bold">
                {currentUser?.store?.name}/{url}
              </span>{' '}
              is being used
            </p>
          </div>
        )}
      </div>

      {updatingProduct ? (
        <div className="w-full rounded-md border border-gray-200 flex items-center justify-center h-96">
          <Spinner />
        </div>
      ) : (
        <Tabs
          selectedIndex={activeTabIndex}
          onSelect={handleTabIndex}
          className="w-full"
        >
          <TabList>
            <Tab>Details</Tab>
            <Tab>Media</Tab>
            <Tab>Discounts</Tab>
            <Tab>Content</Tab>
          </TabList>

          <TabPanel>
            <form className="w-full flex flex-col gap-4 border border-gray-200 rounded-md bg-white p-4">
              <div className="flex items-start w-full gap-4">
                <div className="flex flex-col gap-4 w-6/12">
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Platform</p>
                    <Select
                      value={defaultPlatform}
                      options={platforms}
                      onChange={handlePlatform}
                      placeholder="Categories"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          fontSize: '12px',
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 99999,
                          position: 'relative',
                          height: 35,
                          minHeight: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 999999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between w-full">
                      <p className="text-stone-600 text-xs">Title</p>
                      <p className="text-xs text-stone-600">
                        {title.length}/50
                      </p>
                    </div>
                    <input
                      type="text"
                      className={`border ${
                        emptyFields.includes('field1')
                          ? 'border-red-300'
                          : 'border-gray-200'
                      } hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                      placeholder="Title"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      maxLength={50}
                    />
                  </div>

                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between">
                      <p className="text-stone-600 text-xs">
                        Summary (optional)
                      </p>
                      <div>
                        <p className="text-xs text-stone-600">
                          {description.length}/75
                        </p>
                      </div>
                    </div>
                    <textarea
                      type="text"
                      className="border border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 bg-gray-50 resize-none text-xs"
                      placeholder="Summary"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      maxLength={75}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Categories</p>
                    <Select
                      options={platform === 'Notion' ? notionCats : framerCats}
                      onChange={handleCategory}
                      value={category}
                      isMulti
                      isOptionDisabled={(option) => category.length >= 5}
                      placeholder="Categories"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          fontSize: '12px',
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 99999,
                          position: 'relative',
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 999999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">URL</p>
                    <div className="flex w-full">
                      <div
                        className={`rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 ${
                          urlTaken ? 'border-red-400' : 'border-gray-200'
                        } flex items-center p-2 pr-0.5`}
                      >
                        <p className="text-xs">
                          fruntt.com/{currentUser?.store?.name}/
                        </p>
                      </div>
                      <input
                        type="text"
                        placeholder="URL"
                        className={`border text-xs border-l-0 ${
                          urlTaken ? 'border-red-400' : 'border-gray-200'
                        } bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1`}
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                      />
                    </div>
                    {urlTaken && (
                      <p className="text-xs text-stone-800">
                        This URL is taken
                      </p>
                    )}
                  </div>

                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-2">
                      <Switch
                        size="small"
                        checked={refund}
                        onChange={(checked) => setRefund(checked)}
                        style={{
                          backgroundColor: refund
                            ? 'rgb(41 37 36)'
                            : 'rgb(229 231 235)',
                          borderColor: '#000000',
                        }}
                      />
                      <p className="text-xs text-stone-600">
                        Define a refund policy
                      </p>
                    </div>
                    {reDate && refund ? (
                      <p className="text-stone-800 text-xs">
                        Last updated {moment(reDate).format('MMM Do, YYYY')}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  {refund ? (
                    <div className="flex flex-col w-full gap-2">
                      <input
                        type="text"
                        className={`border border-gray-200 hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                        placeholder="30 Day Full Refund"
                        onChange={(e) => setReTitle(e.target.value)}
                        value={reTitle}
                      />
                      <textarea
                        placeholder="How your policy works.."
                        className="border border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full h-28 rounded-md p-2 bg-gray-50 resize-none text-xs"
                        onChange={(e) => setReText(e.target.value)}
                        value={reText}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="w-6/12 flex flex-col gap-4 items-start">
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Call to action</p>
                    <Select
                      options={actionOptions}
                      onChange={handleAction}
                      value={formattedActionValue}
                      placeholder="Call to action"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          fontSize: '12px',
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 99999,
                          position: 'relative',
                          height: 35,
                          minHeight: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 999999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Price</p>
                    <div className="flex w-full items-start">
                      <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
                        <p className="text-xs">
                          {currentUser?.currency?.symbol}
                        </p>
                      </div>
                      <input
                        type="number"
                        className={`border ${
                          emptyFields.includes('field4')
                            ? 'border-red-300'
                            : 'border-gray-200'
                        } hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-tr-md rounded-br-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder={payChoice ? '$9+' : '$9'}
                        value={price}
                        step={1}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex items-center gap-4">
                    {payChoice ? (
                      ''
                    ) : (
                      <div className="flex items-center gap-2">
                        <Switch
                          size="small"
                          checked={free}
                          onChange={handleFree}
                          style={{
                            backgroundColor: free
                              ? 'rgb(41 37 36)'
                              : 'rgb(229 231 235)',
                            borderColor: '#000000',
                          }}
                        />
                        <p className="text-xs text-stone-600">Free item</p>
                      </div>
                    )}
                    {free ? (
                      ''
                    ) : (
                      <div className="flex items-center gap-2">
                        <Switch
                          size="small"
                          checked={payChoice}
                          onChange={(checked) => setPayChoice(checked)}
                          style={{
                            backgroundColor: payChoice
                              ? 'rgb(41 37 36)'
                              : 'rgb(229 231 235)',
                            borderColor: '#000000',
                          }}
                        />
                        <p className="text-xs text-stone-600">
                          Customer sets price
                        </p>
                      </div>
                    )}
                  </div>

                  {payChoice ? (
                    <div className="flex items-center w-full">
                      <div className="flex flex-col w-6/12">
                        <p className="text-stone-600 text-xs">Minimum</p>
                        <div className="flex w-full items-start">
                          <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
                            <p className="text-xs">
                              {currentUser?.currency?.symbol}
                            </p>
                          </div>
                          <input
                            type="number"
                            className={`border ${
                              emptyFields.includes('field4')
                                ? 'border-red-300'
                                : 'border-gray-200'
                            } hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-tr-md rounded-br-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                            placeholder={payChoice ? '$9+' : '$9'}
                            value={price}
                            step={1}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col w-6/12 ml-2">
                        <p className="text-stone-600 text-xs">Suggested</p>
                        <input
                          type="number"
                          className={`border ${
                            emptyFields.includes('field4')
                              ? 'border-red-300'
                              : 'border-gray-200'
                          } hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 focus:bg-gray-200 focus:border-gray-200`}
                          placeholder={payChoice ? '$9+' : '$9'}
                          value={suggestedPrice}
                          step={1}
                          onChange={(e) => setSuggestedPrice(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {versions.length > 0 && (
                    <div className="flex flex-col gap-1 w-full">
                      <p className="text-xs text-stone-600">Variations</p>
                      {versions.map((version, index) => (
                        <div
                          className="flex items-center w-full gap-2"
                          key={index}
                        >
                          <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                            <div className="flex flex-col">
                              <p className="text-xs text-stone-800">
                                {version?.title}
                              </p>
                              <p className="text-xs text-stone-600">
                                {version?.description}
                              </p>
                            </div>
                            <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center text-xs">
                              <p className="text-xs text-stone-800">
                                ${version?.price}
                              </p>
                            </div>
                          </div>
                          <X
                            size={18}
                            className="text-red-400 hover:cursor-pointer"
                            onClick={() => handleRemoveVariation(index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {add ? (
                    <div className="w-full flex flex-col gap-1 items-start">
                      <p className="text-xs text-stone-600">Adding variation</p>
                      <input
                        type="text"
                        className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder="Title"
                        onChange={(e) => setVersionTitle(e.target.value)}
                      />
                      <textarea
                        type="text"
                        className="border border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full h-12 rounded-md p-2 bg-gray-50 resize-none text-xs"
                        placeholder="Summary"
                        onChange={(e) => setVersionSum(e.target.value)}
                        maxLength={75}
                      />
                      <input
                        type="number"
                        className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder="Price"
                        step={1}
                        onChange={(e) => setVersionPrice(e.target.value)}
                      />
                      <div className="flex items-center gap-2">
                        <button
                          type="button"
                          className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                          onClick={(e) => setAdd(!add)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                          onClick={handleAddVariation}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setAdd(!add)}
                      className="rounded-md bg-gray-50 border border-gray-200 hover:bg-gray-200 text-xs text-stone-800 p-2 flex flex-col items-center justify-center w-full"
                    >
                      <p className="text-xs text-stone-800 flex items-center gap-1">
                        Add Variation
                        <Plus size={12} />
                      </p>
                      <p className="text-xs text-stone-600">
                        Offer different variations of your item
                      </p>
                    </button>
                  )}
                </div>
              </div>

              {hasInfo && !addDescription ? (
                <>
                  <div className="flex flex-col pb-10">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-xs text-stone-600">Description</p>
                    </div>
                    <ReactQuill
                      value={info}
                      onChange={setInfo}
                      className="h-80"
                      placeholder="Start typing description here..."
                    />
                  </div>
                </>
              ) : (
                <>
                  {addDescription ? (
                    <div className="flex flex-col pb-10 gap-1">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-xs text-stone-600">Description</p>

                        <div>
                          <button
                            type="button"
                            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                            onClick={handleCancelAddDes}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <ReactQuill
                        value={info}
                        onChange={setInfo}
                        className="h-80"
                        placeholder="Start typing description here..."
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center border border-gray-200 w-full rounded-md p-4">
                      <p className="text-stone-800 text-sm">Description</p>
                      <p className="text-stone-600 text-xs text-center w-96">
                        Tell potential customers more about your item. This is
                        visible on your item detail pages.
                      </p>
                      <button
                        type="button"
                        className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md pt-1 pb-1 pl-2 pr-2 mt-2"
                        onClick={(e) => setAddDescription(!addDescription)}
                      >
                        Add <Plus size={12} />
                      </button>
                    </div>
                  )}
                </>
              )}
            </form>
          </TabPanel>

          <TabPanel>
            <div className="border border-gray-200 rounded-md bg-white p-4 flex flex-col gap-4">
              <CoverImage
                product={product}
                productId={product._id}
                image={image}
                setImage={setImage}
                refetchProduct={refetchProduct}
                setActiveTabIndex={setActiveTabIndex}
                setCic={setCic}
              />
            </div>
          </TabPanel>
          <TabPanel>
            {product?.approved ? (
              <Discounts
                productId={product?._id}
                editDisActive={editDisActive}
                setEditDisActive={setEditDisActive}
              />
            ) : (
              <div className="flex items-center justify-center border border-gray-200 w-full rounded-md p-4 h-96">
                <div className="flex flex-col gap-2 items-center">
                  <p className="text-stone-800 text-sm">
                    Discounts not available
                  </p>
                  <p className="text-stone-600 text-xs text-center w-72">
                    Creating and offering discounts is only available once your
                    item is approved. Publish your item to be approved.
                  </p>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <div className="flex flex-col gap-4 items-start border border-gray-200 rounded-md w-full p-4 bg-white">
              <div className="flex items-end justify-between w-full">
                <div className="flex flex-col justify-center bg-white">
                  <p className="text-xs text-stone-800">Item Content</p>
                  <p className="text-xs text-stone-600">
                    Add any project links and content for customers to recieve
                    after purchase
                  </p>
                </div>
                <button
                  type="button"
                  onClick={(e) => setShowInfo(!showInfo)}
                  className="flex items-end justify-center text-xs text-stone-600"
                >
                  Project links?
                </button>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex flex-col">
                  <p className="text-stone-600 text-xs">
                    {versions.length > 0
                      ? `${platform === 'Notion' ? 'Duplicate' : 'Remix'} link`
                      : `${platform === 'Notion' ? 'Duplicate' : 'Remix'} link`}
                  </p>
                </div>
                <input
                  type="text"
                  className={`border ${
                    emptyFields.includes('field1')
                      ? 'border-red-300'
                      : 'border-gray-200'
                  } hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                  placeholder="https://carbonated-hill-6bc.notion.site/43be8a4d9"
                  onChange={(e) => setDupLink(e.target.value)}
                  value={dupLink}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                {versions.map((version, index) => (
                  <div className="flex flex-col w-full" key={index}>
                    <p className="text-xs text-stone-600">
                      {platform === 'Notion' ? 'Duplicate' : 'Remix'} link for
                      variation:{' '}
                      <span className="font-bold">{version?.title}</span>
                    </p>
                    <input
                      type="text"
                      className={`border border-gray-200 hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                      placeholder={
                        platform === 'Notion'
                          ? 'https://carbonated-hill-6bc.notion.site/43be8a4d9'
                          : 'https://framer.com/projects/new?duplicate=xrop2yEDr5RWhR50BPJM'
                      }
                      onChange={(e) =>
                        setLinkForIndex(index, e.target.value, version?.title)
                      }
                      value={version?.link}
                    />
                  </div>
                ))}
              </div>
              {hasContent && !addContent ? (
                <>
                  <div className="flex flex-col pb-10 w-full">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-xs text-stone-600">
                        Additional content
                      </p>
                    </div>
                    <ReactQuill
                      value={productContent}
                      onChange={setProductContent}
                      className="h-80"
                      placeholder="Start typing content here..."
                    />
                  </div>
                </>
              ) : (
                <>
                  {addContent ? (
                    <div className="flex flex-col gap-2 pb-10 w-full">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-xs text-stone-800">
                          Additional content
                        </p>

                        <div>
                          <button
                            type="button"
                            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                            onClick={handleCancelAddCon}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <ReactQuill
                        value={productContent}
                        onChange={setProductContent}
                        className="h-72"
                        placeholder="Start typing content here..."
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center border border-gray-200 w-full rounded-md p-4">
                      <p className="text-stone-800 text-sm">
                        Additional Content
                      </p>
                      <p className="text-stone-600 text-xs text-center w-96">
                        Add any written content like instructions or
                        explanations. This will be visible on all orders.
                      </p>
                      <button
                        type="button"
                        className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md pt-1 pb-1 pl-2 pr-2 mt-2"
                        onClick={(e) => setAddContent(!addContent)}
                      >
                        Add <Plus size={12} />
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default EditDesktop;
