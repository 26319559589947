import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Bookmark, ExternalLink, X } from 'react-feather';
import Modal from 'react-modal';
import moment from 'moment';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

const LibraryDesktop = ({ orders }) => {
  const [open, setOpen] = useState(false);
  const [oiv, setOiv] = useState({});

  const handleOpenModal = (order) => {
    setOiv(order);
    setOpen(true);
  };

  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  const endOffset = itemOffset + itemsPerPage;

  const currentOrders = orders.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(orders.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };
  //end of pagination stuff

  return orders.length ? (
    <div className="mx-auto w-full">
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(!open)}
        style={customStyles}
        contentLabel="Item Modal"
      >
        <div className="flex flex-col gap-3 w-96">
          <div className="w-full flex justify-between">
            <div className="flex flex-col">
              <p className="text-stone-800 text-sm">Purchase: {oiv?._id}</p>
              <p className="text-xs text-stone-800">
                {moment(oiv?.placedOn).format('MMMM Do, YYYY')}
              </p>
            </div>
            <X
              size={18}
              className="text-red-400 hover:cursor-pointer"
              onClick={() => setOpen(!open)}
            />
          </div>

          <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Item</p>
              <p className="text-xs text-stone-800">{oiv?.item?.title}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Purchased From</p>
              <Link to={`/${oiv?.item?.pageName}`} target="_blank">
                <div className="flex gap-1">
                  <p className="text-stone-800 text-xs">
                    fruntt.com/{oiv?.item?.pageName}
                  </p>
                  <ExternalLink size={14} className="text-stone-800" />
                </div>
              </Link>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-600">Total</p>

              <p className="text-xs text-stone-800 font-bold">
                $
                {oiv?.total?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>

          <div className="w-full flex items-center justify-end">
            <div className="flex items-center gap-2">
              <Link
                to={`/order/${oiv?._id}?oat=${oiv?.accessToken}`}
                className="text-xs text-stone-800 p-1 pl-2 pr-2 bg-gray-200 rounded-md"
                target="_blank"
              >
                View Order
              </Link>
              {oiv?.disableLink ? (
                ''
              ) : (
                <a
                  href={oiv?.item?.duplicationLink}
                  className="text-xs text-stone-800 p-1 pl-2 pr-2 bg-gray-200 rounded-md flex gap-1"
                  target="_blank"
                >
                  Project Link <ExternalLink size={14} />
                </a>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full mx-auto flex flex-col gap-2">
        <div className="w-full flex justify-between items-end">
          <div className="flex flex-col justify-center border border-gray-200 bg-white rounded-md p-2">
            <p className="text-sm text-stone-800">Library</p>
            <p className="text-xs text-stone-600">View all your purchases</p>
          </div>

          <div className="flex items-center">
            <p className="text-stone-600 text-xs">
              {orders.length > 1
                ? `${orders.length} purchases`
                : `${orders.length} purchase`}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {currentOrders.map((order, index) => (
            <button
              type="button"
              key={index}
              onClick={() => handleOpenModal(order)}
            >
              <div className="border border-gray-200 rounded-md bg-white relative flex items-start hover:bg-gray-50 p-2">
                <div className="w-2/12 h-full">
                  <img
                    src={order?.item?.coverImages[0]?.url}
                    className="rounded-md w-full h-20 object-cover"
                  />
                </div>

                <div className="w-10/12 flex flex-col items-start pl-2">
                  <p className="text-stone-800 text-sm">{order?.item?.title}</p>

                  <p className="text-xs text-stone-600 text-left">
                    {order?.item?.description}
                  </p>
                </div>
              </div>
            </button>
          ))}
        </div>
        {orders.length > 5 && (
          <div className="w-full flex justify-end mt-2">
            <div className="">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                pageCount={pageCount}
                previousLabel="Prev"
                renderOnZeroPageCount={null}
                className="flex items-center"
                activeLinkClassName="activePage"
                pageLinkClassName="notActivePage"
                breakLinkClassName="breakLink"
                disabledClassName="disabled"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
      style={{ height: '600px' }}
    >
      <div className="flex flex-col items-center gap-2">
        <Bookmark size={18} className="text-stone-800" />
        <p className="text-stone-800 text-sm">Library empty</p>
        <p className="text-stone-600 text-xs text-center w-60">
          Any purchases you make will be seen here
        </p>
      </div>
    </div>
  );
};

export default LibraryDesktop;
