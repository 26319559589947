import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  ChevronRight,
  CornerRightDown,
  CreditCard,
  DownloadCloud,
  File,
  Layout,
  List,
  Mail,
  Tag,
  Truck,
} from 'react-feather';
import { Link } from 'react-router-dom';

const Features = () => {
  return isMobile ? (
    <div className="w-full flex flex-col items-start gap-2">
      <p className="text-stone-600 text-sm flex items-center gap-1">
        Features <CornerRightDown size={16} className="text-stone-800" />
      </p>
      <div className="flex flex-col items-start gap-4">
        <div className="flex flex-col gap-2 bg-gray-50 p-2 border border-gray-200 rounded-md w-full h-24">
          <CreditCard size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Secure Payments</p>
            <p className="text-xs text-stone-600">
              All transactions are securely processed through Stripe.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <File size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">MoR</p>
            <p className="text-xs text-stone-600">
              We act as your Merchant of Record, serving as the legal
              representative for all transactions.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <List size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Variations</p>
            <p className="text-xs text-stone-600">
              Add variations to offer different versions of the same item. Pro,
              Basic, etc..
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <Tag size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Discounts</p>
            <p className="text-xs text-stone-600">
              Easily create and offer discounts on your items to attract more
              customers.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <Mail size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Delivery</p>
            <p className="text-xs text-stone-600">
              Orders and project links automatically delivered after purchase.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <Link
          to="/docs"
          state={{ view: 'features' }}
          className="flex items-center text-xs text-stone-800"
        >
          See all <ChevronRight size={14} />
        </Link>
      </div>
    </div>
  ) : (
    <div className="w-4/12 flex flex-col gap-2">
      <p className="text-stone-600 text-sm flex items-center gap-1">
        Features <CornerRightDown size={16} className="text-stone-800" />
      </p>
      <div className="flex flex-col w-full items-start gap-3">
        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <CreditCard size={22} />
          <div className="flex flex-col">
            <p className="text-xs text-stone-800 font-bold">Secure Payments</p>
            <p className="text-xs text-stone-600">
              All transactions are securely processed through Stripe.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <File size={20} />
          <div className="flex flex-col">
            <p className="text-xs text-stone-800 font-bold">MoR</p>
            <p className="text-xs text-stone-600">
              We act as your Merchant of Record, serving as the legal
              representative for all transactions.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <List size={22} />
          <div className="flex flex-col">
            <p className="text-xs text-stone-800 font-bold">Variations</p>
            <p className="text-xs text-stone-600">
              Add variations to offer different versions of the same item. Pro,
              Basic, etc..
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <Tag size={22} />
          <div className="flex flex-col">
            <p className="text-xs text-stone-800 font-bold">Discounts</p>
            <p className="text-xs text-stone-600">
              Easily create and offer discounts on your items to attract more
              customers.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 bg-gray-50 border border-gray-200 rounded-md w-full h-24">
          <Mail size={22} />
          <div className="flex flex-col">
            <p className="text-xs text-stone-800 font-bold">Delivery</p>
            <p className="text-xs text-stone-600">
              Orders and project links automatically delivered after purchase.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <Link
          to="/docs"
          state={{ view: 'features' }}
          className="flex items-center text-xs text-stone-800"
        >
          See all <ChevronRight size={14} />
        </Link>
      </div>
    </div>
  );
};

export default Features;
