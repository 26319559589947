import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  X,
} from 'react-feather';
import { Link } from 'react-router-dom';
import { BiSolidBank } from 'react-icons/bi';
import { BsStripe } from 'react-icons/bs';
import { minPayouts } from '../../../utils/minPayouts';
import ReactCountryFlag from 'react-country-flag';
import { isMobile } from 'react-device-detect';

const Payments = ({ setDocsView, currentUser }) => {
  const style = currentUser
    ? 'h-fit mx-auto max-w-2xl flex flex-col items-start gap-4'
    : 'h-fit mx-auto max-w-2xl flex flex-col items-center mt-24 gap-4';

  const [searchTerm, setSearchTerm] = useState('');

  const filteredMins = minPayouts.filter((country) =>
    country.country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [seeBank, setSeeBank] = useState(false);
  const [seeStr, setSeeStr] = useState(false);
  const [seeMins, setSeeMins] = useState(false);

  return isMobile ? (
    <div className="h-fit mx-auto w-full flex flex-col items-center mt-16 gap-4 p-4">
      <div className="flex justify-between items-end w-full">
        <div className="flex flex-col items-start w-full border border-gray-200 p-2 rounded-md bg-gray-50">
          <p className="text-sm text-stone-800">Payments & Payouts</p>
          <p className="text-xs text-stone-600">
            Find out everything about payments and payouts below
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center  gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2 mt-1"
          >
            <ChevronLeft size={14} />
            Docs
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col items-start gap-2">
        <div className="flex items-center justify-between w-full">
          <p className="text-xs text-stone-800 font-bold">Payments</p>
          <Link
            to="/pricing"
            className="flex items-center justify-center text-xs text-stone-800"
          >
            See pricing <ChevronRight size={14} />
          </Link>
        </div>
        <div className="flex flex-col items-start gap-4 w-full">
          <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md bg-gray-50 p-2">
            <p className="text-xs text-stone-800">Payments Accepted</p>
            <p className="text-xs text-stone-600">
              Currently, we support card payments from over 150 countries. Our
              hosted checkout page allows customers to securely view and
              purchase project links using a card of their choice. More payment
              methods are on the way, including Apple Pay and Google Pay.
              Selling your project links and reaching customers around the world
              has never been easier.
            </p>
            <p className="text-xs text-stone-600 font-bold">
              All payments are in USD and are converted to your bank or Stripe
              accounts currency for payouts
            </p>
          </div>
          <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md bg-gray-50 p-2">
            <p className="text-xs text-stone-800">Taxes</p>
            <p className="text-xs text-stone-600">
              As a platform, we calculate, collect, and remit tax for all orders
              where this is required. All customers are required to enter a
              country and postal code during the checkout flow. Depending on the
              location of a customer, we will determine if tax collection is
              required and calculate according to the orders total and customers
              location.
            </p>
            <p className="text-xs text-stone-600">
              As a seller, you are still responsible for paying taxes on any
              income you generate through sales. It is important to make sure
              all account and personal details are always up to date and
              accurate. All required forms will be available in a sellers
              dashboard at the end of each calendar year.
            </p>
            <p className="text-xs text-stone-600">
              We calculate, collect and remit Sales Tax, VAT, and GST.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-start gap-2">
        <p className="text-xs text-stone-800 font-bold">Payouts</p>
        <div className="flex flex-col items-start gap-4 w-full border border-gray-200 rounded-md bg-gray-50 p-2">
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-xs text-stone-800">Payout Details</p>
            <p className="text-xs text-stone-600">
              Payouts are how we as a platform pay sellers for the revenue they
              generate from sales. When you first create an account, payouts are
              disabled and items cannot be published for sale. Once a payout
              option is connected, you are ready for sales.
            </p>
            <p className="text-xs text-stone-600">
              Sellers have two payout options to choose from:
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <button
            type="button"
            onClick={() => setSeeBank(!seeBank)}
            className="border border-gray-200 bg-gray-50 rounded-md flex flex-col p-2 w-full"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                <BiSolidBank className="mr-1 text-stone-800 text-2xl" />
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">Bank Account</p>
                  <p className="text-xs text-stone-600">
                    Be paid out to a bank account
                  </p>
                </div>
              </div>
              {seeBank ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden w-full flex flex-col gap-2 ${
                seeBank ? 'max-h-72' : 'max-h-0'
              }`}
            >
              <p className="text-stone-600 text-xs text-left mt-4">
                When receiving payouts to a bank account, you can choose between
                monthly or weekly payouts. Payouts occur when you accumulate a
                balance in your Fruntt account as you make sales.
              </p>
              <p className="text-stone-600 text-xs text-left">
                You can view your available and pending balances, as well as all
                payouts, by navigating to the Payouts page in your dashboard.
              </p>
              <p className="text-stone-600 text-xs text-left">
                Funds from sales are not immediately available for payout and
                remain in your pending balance. As funds become available, they
                move to your available balance and will be paid out.
              </p>
              <p className="text-stone-600 text-xs text-left font-bold">
                Account deletion or removing your bank is not allowed if you
                have an available or pending balance.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => setSeeStr(!seeStr)}
            className="border border-gray-200 bg-gray-50 rounded-md flex flex-col p-2 w-full"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                <BsStripe className="mr-1 text-stone-800 text-xl" />
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">Stripe Account</p>
                  <p className="text-xs text-stone-600">
                    Be paid out to a Stripe account
                  </p>
                </div>
              </div>
              {seeStr ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden w-full flex flex-col gap-2 ${
                seeStr ? 'max-h-72' : 'max-h-0'
              }`}
            >
              <p className="text-stone-600 text-xs text-left mt-4">
                When receiving payouts to a Stripe account, funds are
                immediately transferred to your connected account after every
                successful purchase. You will not accumulate a balance in your
                Fruntt account, only in your Stripe account. What you do with
                your funds is up to you.
              </p>
              <p className="text-stone-600 text-xs text-left">
                This option is useful and can provide a more straightforward
                experience if you already have a Stripe account set up that has
                received payments in the past.
              </p>
              <p className="text-stone-600 text-xs text-left">
                You can disconnect a Stripe account at any time.
              </p>
            </div>
          </button>
        </div>
        <div className="w-full flex flex-col gap-2 bg-gray-50 border border-gray-200 p-2 rounded-md">
          <p className="text-stone-600 text-xs text-left">
            Both options will redirect you to{' '}
            <span>
              <a
                href="https://stripe.com"
                target="_blank"
                className="font-bold"
              >
                stripe.com
              </a>
            </span>{' '}
            to safely and securely collect the necessary details required to
            process payments and payouts.
          </p>
        </div>
        <div className="flex flex-col items-start gap-4 w-full border border-gray-200 rounded-md bg-gray-50 p-2">
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-xs text-stone-800">Payout Minimums</p>
            <p className="text-xs text-stone-600">
              Depending on what country you are located in, you will need to
              reach a minimum balance to receive payouts. View the table below
              to see the minimum for each country.
            </p>
            {seeMins ? (
              <div className="flex flex-col gap-1 w-full items-start">
                <div className="w-full flex items-end justify-between">
                  <button
                    type="button"
                    onClick={() => setSeeMins(!seeMins)}
                    className="text-xs text-stone-800 flex items-center gap-1"
                  >
                    Close <X size={14} />
                  </button>
                  <input
                    type="text"
                    placeholder="Search countries..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 rounded-md w-52 text-xs"
                  />
                </div>
                {filteredMins?.length ? (
                  <div className="grid grid-cols-2 gap-2 w-full">
                    {filteredMins?.map((min, index) => (
                      <div
                        key={index}
                        className="flex flex-col gap-1 p-2 border border-gray-200 rounded-md"
                      >
                        <div className="flex items-center gap-1">
                          <ReactCountryFlag
                            countryCode={min?.code}
                            svg
                            className="w-6 h-4"
                          />
                          <p className="text-xs text-stone-600">
                            {min?.country}
                          </p>
                        </div>
                        <p className="text-xs text-stone-800 font-bold">
                          {min?.amount}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full border border-gray-200 rounded-md flex items-center justify-center h-24">
                    <p className="text-xs text-stone-800">
                      Country not available
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex items-start mt-2">
                <button
                  type="button"
                  onClick={() => setSeeMins(!seeMins)}
                  className="p-1 pl-2 pr-2 border border-gray-200 rounded-md text-xs text-stone-800 flex items-center justify-center gap-1"
                >
                  See minimums
                  <ChevronDown size={14} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={style}>
      <div className="flex justify-between items-end w-full">
        <div className="flex flex-col items-start w-full border border-gray-200 p-2 rounded-md bg-gray-50">
          <p className="text-sm text-stone-800">Payments & Payouts</p>
          <p className="text-xs text-stone-600">
            Find out everything about payments and payouts below
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center  gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2 mt-1"
          >
            <ChevronLeft size={14} />
            Docs
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col items-start gap-2">
        <div className="flex items-center justify-between w-full">
          <p className="text-xs text-stone-800 font-bold">Payments</p>
          <Link
            to="/pricing"
            className="flex items-center justify-center text-xs text-stone-800"
          >
            See pricing <ChevronRight size={14} />
          </Link>
        </div>
        <div className="flex flex-col items-start gap-4 w-full">
          <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md bg-gray-50 p-2">
            <p className="text-xs text-stone-800">Payments Accepted</p>
            <p className="text-xs text-stone-600">
              Currently, we support card payments from over 150 countries. Our
              hosted checkout page allows customers to securely view and
              purchase project links using a card of their choice. More payment
              methods are on the way, including Apple Pay and Google Pay.
              Selling your project links and reaching customers around the world
              has never been easier.
            </p>
            <p className="text-xs text-stone-600 font-bold">
              All payments are in USD and are converted to your bank or Stripe
              accounts currency for payouts
            </p>
          </div>
          <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md bg-gray-50 p-2">
            <p className="text-xs text-stone-800">Taxes</p>
            <p className="text-xs text-stone-600">
              As a platform, we calculate, collect, and remit tax for all orders
              where this is required. All customers are required to enter a
              country and postal code during the checkout flow. Depending on the
              location of a customer, we will determine if tax collection is
              required and calculate according to the orders total and customers
              location.
            </p>
            <p className="text-xs text-stone-600">
              As a seller, you are still responsible for paying taxes on any
              income you generate through sales. It is important to make sure
              all account and personal details are always up to date and
              accurate. All required forms will be available in a sellers
              dashboard at the end of each calendar year.
            </p>
            <p className="text-xs text-stone-600">
              We calculate, collect and remit Sales Tax, VAT, and GST.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-start gap-2">
        <p className="text-xs text-stone-800 font-bold">Payouts</p>
        <div className="flex flex-col items-start gap-4 w-full border border-gray-200 rounded-md bg-gray-50 p-2">
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-xs text-stone-800">Payout Details</p>
            <p className="text-xs text-stone-600">
              Payouts are how we as a platform pay sellers for the revenue they
              generate from sales. When you first create an account, payouts are
              disabled and items cannot be published for sale. Once a payout
              option is connected, you are ready for sales.
            </p>
            <p className="text-xs text-stone-600">
              Sellers have two payout options to choose from:
            </p>
          </div>
        </div>
        <div className="flex items-start gap-2 w-full">
          <button
            type="button"
            onClick={() => setSeeBank(!seeBank)}
            className="border border-gray-200 bg-gray-50 rounded-md flex flex-col p-2 w-6/12"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                <BiSolidBank className="mr-1 text-stone-800 text-2xl" />
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">Bank Account</p>
                  <p className="text-xs text-stone-600">
                    Be paid out to a bank account
                  </p>
                </div>
              </div>
              {seeBank ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden w-full flex flex-col gap-2 ${
                seeBank ? 'max-h-72' : 'max-h-0'
              }`}
            >
              <p className="text-stone-600 text-xs text-left mt-4">
                When receiving payouts to a bank account, you can choose between
                monthly or weekly payouts. Payouts occur when you accumulate a
                balance in your Fruntt account as you make sales.
              </p>
              <p className="text-stone-600 text-xs text-left">
                You can view your available and pending balances, as well as all
                payouts, by navigating to the Payouts page in your dashboard.
              </p>
              <p className="text-stone-600 text-xs text-left">
                Funds from sales are not immediately available for payout and
                remain in your pending balance. As funds become available, they
                move to your available balance and will be paid out.
              </p>
              <p className="text-stone-600 text-xs text-left font-bold">
                Account deletion or removing your bank is not allowed if you
                have an available or pending balance.
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => setSeeStr(!seeStr)}
            className="border border-gray-200 bg-gray-50 rounded-md flex flex-col p-2 w-6/12"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                <BsStripe className="mr-1 text-stone-800 text-xl" />
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">Stripe Account</p>
                  <p className="text-xs text-stone-600">
                    Be paid out to a Stripe account
                  </p>
                </div>
              </div>
              {seeStr ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
            </div>

            <div
              className={`transition-[max-height] duration-300 ease-in-out overflow-hidden w-full flex flex-col gap-2 ${
                seeStr ? 'max-h-72' : 'max-h-0'
              }`}
            >
              <p className="text-stone-600 text-xs text-left mt-4">
                When receiving payouts to a Stripe account, funds are
                immediately transferred to your connected account after every
                successful purchase. You will not accumulate a balance in your
                Fruntt account, only in your Stripe account. What you do with
                your funds is up to you.
              </p>
              <p className="text-stone-600 text-xs text-left">
                This option is useful and can provide a more straightforward
                experience if you already have a Stripe account set up that has
                received payments in the past.
              </p>
              <p className="text-stone-600 text-xs text-left">
                You can disconnect a Stripe account at any time.
              </p>
            </div>
          </button>
        </div>
        <div className="w-full flex flex-col gap-2 bg-gray-50 border border-gray-200 p-2 rounded-md">
          <p className="text-stone-600 text-xs text-left">
            Both options will redirect you to{' '}
            <span>
              <a
                href="https://stripe.com"
                target="_blank"
                className="font-bold"
              >
                stripe.com
              </a>
            </span>{' '}
            to safely and securely collect the necessary details required to
            process payments and payouts.
          </p>
        </div>
        <div className="flex flex-col items-start gap-4 w-full border border-gray-200 rounded-md bg-gray-50 p-2">
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-xs text-stone-800">Payout Minimums</p>
            <p className="text-xs text-stone-600">
              Depending on what country you are located in, you will need to
              reach a minimum balance to receive payouts. View the table below
              to see the minimum for each country.
            </p>
            {seeMins ? (
              <div className="flex flex-col gap-1 w-full items-start">
                <div className="w-full flex items-end justify-between">
                  <button
                    type="button"
                    onClick={() => setSeeMins(!seeMins)}
                    className="text-xs text-stone-800 flex items-center gap-1"
                  >
                    Close <X size={14} />
                  </button>
                  <input
                    type="text"
                    placeholder="Search countries..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 rounded-md w-52 text-xs"
                  />
                </div>
                {filteredMins?.length ? (
                  <div className="grid grid-cols-3 gap-2 w-full">
                    {filteredMins?.map((min, index) => (
                      <div
                        key={index}
                        className="flex flex-col gap-1 p-2 border border-gray-200 rounded-md"
                      >
                        <div className="flex items-center gap-1">
                          <ReactCountryFlag
                            countryCode={min?.code}
                            svg
                            className="w-6 h-4"
                          />
                          <p className="text-xs text-stone-600">
                            {min?.country}
                          </p>
                        </div>
                        <p className="text-xs text-stone-800 font-bold">
                          {min?.amount}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full border border-gray-200 rounded-md flex items-center justify-center h-24">
                    <p className="text-xs text-stone-800">
                      Country not available
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex items-start mt-2">
                <button
                  type="button"
                  onClick={() => setSeeMins(!seeMins)}
                  className="p-1 pl-2 pr-2 border border-gray-200 rounded-md text-xs text-stone-800 flex items-center justify-center gap-1"
                >
                  See minimums
                  <ChevronDown size={14} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
