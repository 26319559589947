import React, { useEffect, useState } from 'react';
import Footer from '../../../components/Footer/Footer';
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs';
import {
  Book,
  ChevronRight,
  Globe,
  Home,
  Play,
  Video,
  Youtube,
} from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import { useGetDemosQuery } from '../../../api/demoApiSlice';
import { Spinner } from 'flowbite-react';
import { isMobile } from 'react-device-detect';
import MobileDocs from './MobileDocs';
import ReactPlayer from 'react-player/youtube';
import Demos from './Demos';
import Countries from './Countries';
import Cookies from 'js-cookie';
import Features from './Features';
import Payments from './Payments';

const Docs = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const {
    data: demos,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetDemosQuery();
  const location = useLocation();
  const [docsView, setDocsView] = useState(location?.state?.view || 'home');

  useEffect(() => {
    refetch();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  let content;

  if (isLoading || isFetching) {
    content = (
      <>
        <Navbar />
        <div className="h-screen mx-auto max-w-6xl">
          <div className="flex items-center justify-center w-full mx-auto h-screen mt-20">
            <Spinner />
          </div>
        </div>
        <Footer />
      </>
    );
  } else if (isSuccess) {
    content = (
      <>
        <Navbar />
        {isMobile ? (
          <MobileDocs
            demos={demos}
            docsView={docsView}
            setDocsView={setDocsView}
          />
        ) : (
          <>
            {docsView === 'home' && (
              <div className="h-screen max-w-6xl flex items-center justify-center mx-auto">
                <div className="w-96 mx-auto flex flex-col gap-2 mb-52">
                  <div className="w-full flex items-end justify-between pb-0">
                    <div className="flex flex-col items-start">
                      <div className="flex items-center">
                        <Book size={14} className="text-stone-800" />
                        <p className="text-sm text-stone-800">Fruntt Docs</p>
                      </div>

                      <p className="text-xs text-stone-600">
                        Browse our docs below to learn more about Fruntt
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => setDocsView('countries')}
                    className="flex items-center justify-between bg-gray-50 w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
                  >
                    <div className="flex flex-col items-start w-8/12">
                      <p className="text-stone-800 text-xs font-bold">
                        Available Countries
                      </p>

                      <p className="text-stone-600 text-xs text-left">
                        Find out what countries Fruntt is available in
                      </p>
                    </div>
                    <ChevronRight size={16} className="text-stone-800" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setDocsView('features')}
                    className="flex items-center justify-between bg-gray-50 w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
                  >
                    <div className="flex flex-col items-start w-8/12">
                      <p className="text-stone-800 text-xs font-bold">
                        Features
                      </p>

                      <p className="text-stone-600 text-xs text-left">
                        Checkout all the features we currently offer
                      </p>
                    </div>
                    <ChevronRight size={16} className="text-stone-800" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setDocsView('payments')}
                    className="flex items-center justify-between bg-gray-50 w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
                  >
                    <div className="flex flex-col items-start w-8/12">
                      <p className="text-stone-800 text-xs font-bold">
                        Payments & Payouts
                      </p>

                      <p className="text-stone-600 text-xs text-left">
                        Find out everything about accepting payments and being
                        paid
                      </p>
                    </div>
                    <ChevronRight size={16} className="text-stone-800" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setDocsView('demos')}
                    className="flex items-center justify-between bg-gray-50 w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
                  >
                    <div className="flex flex-col items-start w-8/12">
                      <p className="text-stone-800 text-xs font-bold">
                        Tutorials
                      </p>
                      <p className="text-stone-600 text-xs text-left">
                        Watch our quick tutorials on how to use various features
                        in Fruntt
                      </p>
                    </div>
                    <ChevronRight size={16} className="text-stone-800" />
                  </button>
                  <button
                    disabled
                    className="h-10 text-xs text-stone-600 w-full border bg-gray-50 border-gray-200 rounded-md p-2"
                  >
                    More on the way
                  </button>
                </div>
              </div>
            )}

            {docsView === 'features' && (
              <Features setDocsView={setDocsView} currentUser={currentUser} />
            )}

            {docsView === 'payments' && (
              <Payments setDocsView={setDocsView} currentUser={currentUser} />
            )}

            {docsView === 'demos' && (
              <Demos
                demos={demos}
                setDocsView={setDocsView}
                currentUser={currentUser}
              />
            )}

            {docsView === 'countries' && (
              <Countries setDocsView={setDocsView} currentUser={currentUser} />
            )}
          </>
        )}
        <Footer />
      </>
    );
  }

  return content;
};

export default Docs;
