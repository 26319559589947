import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import { Rating } from '@mui/material';
import { Badge, Spinner, Timeline, Tooltip } from 'flowbite-react';
import {
  ChevronRight,
  Eye,
  MessageSquare,
  Link as Flink,
  ChevronUp,
  X,
  Repeat,
  ChevronDown,
} from 'react-feather';
import {
  useMarkAsSeenMutation,
  useRefundOrderMutation,
} from '../../../api/ordersApiSlice';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { Switch } from 'antd';
import StripeAmount from '../../../utils/StripeAmount';
import BackBtn from '../../../components/BackBtn';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

const Desktop = ({ order, refetch }) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [openRev, setOpenRev] = useState(false);
  const [openRef, setOpenRef] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [error, setError] = useState('');

  //refund form state
  const [full, setFull] = useState(true);
  const [disable, setDisable] = useState(true);
  const [amount, setAmount] = useState(0);

  const [markAsSeen, { isLoading, isSuccess }] = useMarkAsSeenMutation();
  const [refundOrder, result] = useRefundOrderMutation();

  const version = order?.item?.versions
    ? order?.item?.versions.find((version) => version?._id === order?.versionId)
    : undefined;

  const orderTotal = order?.total || 0; // Ensure order total is defined
  const stripeFeePercentage = orderTotal * 0.029;
  const roundedStripeFee = (
    Math.round(stripeFeePercentage * 100) / 100
  ).toFixed(2);
  const stripeFee = parseFloat(roundedStripeFee) + 0.3;
  const fullStripeFee = stripeFee.toFixed(2);

  const taxAmount = order?.tax / 100;
  const IntFee = Math.round(order?.total * 0.015 * 100) / 100;
  const youMakeAfterFrunttLowFee =
    order?.paymentOrigin === 'US'
      ? Math.round((order?.total - fullStripeFee - 0.25) * 100) / 100
      : Math.round((order?.total - fullStripeFee - IntFee - 0.25) * 100) / 100;
  const youMakeAfterFrunttHighFee =
    order?.paymentOrigin === 'US'
      ? Math.round((order?.total - fullStripeFee - 1) * 100) / 100
      : Math.round((order?.total - fullStripeFee - IntFee - 1) * 100) / 100;
  const refundAmount = (order?.refundAmount / 100).toFixed(2);
  const refHighFee = order?.refundFull
    ? youMakeAfterFrunttHighFee - refundAmount + 1
    : orderTotal - refundAmount - fullStripeFee - 1;
  const refLowFee = order?.refundFull
    ? youMakeAfterFrunttLowFee - refundAmount + 0.25
    : orderTotal - refundAmount - fullStripeFee - 0.25;

  useEffect(() => {
    const markSeen = async () => {
      const seenReq = await markAsSeen({ orderId: order?._id }).unwrap();

      if (seenReq.msg === 'Order seen') {
        currentUser.newOrders = seenReq.newOrders;
        const updatedUser = JSON.stringify(currentUser);
        Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
        refetch();
      }
    };

    if (!order?.seen) {
      markSeen();
    }
  }, []);

  const ElapsedTimeOrDate = ({ orderDate }) => {
    const now = new Date();
    const duration = moment.duration(moment(now).diff(moment(orderDate)));
    const secondsElapsed = duration.asSeconds();
    const minutesElapsed = duration.asMinutes();
    const hoursElapsed = duration.asHours();
    const daysElapsed = duration.asDays();

    let displayText = '';

    if (secondsElapsed < 60) {
      displayText = `${Math.floor(secondsElapsed)} seconds ago`;
    } else if (minutesElapsed < 60) {
      displayText =
        Math.floor(minutesElapsed) == 1
          ? `${Math.floor(minutesElapsed)} minute ago`
          : `${Math.floor(minutesElapsed)} minutes ago`;
    } else if (hoursElapsed < 24) {
      displayText =
        Math.floor(hoursElapsed) == 1
          ? `${Math.floor(hoursElapsed)} hour ago`
          : `${Math.floor(hoursElapsed)} hours ago`;
    } else if (daysElapsed < 3) {
      displayText =
        Math.floor(daysElapsed) == 1
          ? `${Math.floor(daysElapsed)} day ago`
          : `${Math.floor(daysElapsed)} days ago`;
    } else {
      displayText = `${moment(orderDate).format('MMMM D, YYYY')}`;
    }

    return <span>{displayText}</span>;
  };

  const handleFullAmount = (checked) => {
    setAmount(0);
    setFull(checked);
  };

  const handleCloseMod = () => {
    setAmount(0);
    setFull(true);
    setOpenRef(!openRef);
  };

  const handleRefund = async () => {
    setRefunding(true);

    if (amount > order?.total) {
      setError('Refund amount is greater than order total');
      setRefunding(false);
      return;
    }

    if (!full && !amount) {
      setError('Please enter an amount to refund');
      setRefunding(false);
      return;
    }

    try {
      const refundReq = await refundOrder({
        orderId: order?._id,
        fullAmount: full,
        amount: amount,
        disable: disable,
      }).unwrap();

      if (refundReq === 'Order refunded') {
        setOpenRef(!openRef);
        setRefunding(false);
        refetch();
        return;
      } else {
        setRefunding(false);
        setError('There was an error');
        return;
      }
    } catch (err) {
      setRefunding(false);
      setError('Server error');
      console.log(err);
      return;
    }
  };

  useEffect(() => {
    setError('');
  }, [full, amount]);

  return (
    <div className="mx-auto">
      {isLoading ? (
        <div className="w-full flex items-center justify-center h-96">
          <Spinner />
        </div>
      ) : (
        <>
          <Modal
            isOpen={openRef}
            onRequestClose={handleCloseMod}
            style={customStyles}
            contentLabel="Refund Modal"
          >
            {refunding ? (
              <div className="flex items-center justify-center h-52 w-96">
                <Spinner />
              </div>
            ) : (
              <div className="flex flex-col gap-3 w-96">
                <div className="flex flex-col">
                  <p className="text-stone-800 text-sm">Refund Order</p>
                  <p className="text-stone-600 text-xs">
                    Refund full amount or enter an amount
                  </p>
                </div>
                {error && (
                  <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                    <X size={16} className="text-red-500" />
                    <p className="text-stone-800 text-xs">{error}</p>
                  </div>
                )}

                <div className="w-full border border-gray-200 flex flex-col gap-2 rounded-md p-2">
                  <div className="flex flex-col">
                    <p className="text-xs text-stone-600">Order</p>
                    <p className="text-xs text-stone-800">{order?._id}</p>
                  </div>

                  <div className="flex flex-col">
                    <p className="text-xs text-stone-600">Total</p>
                    <p className="text-xs text-stone-800">
                      $
                      {order?.total?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>

                <div className="w-full flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <Switch
                      size="small"
                      onChange={(checked) => setDisable(checked)}
                      checked={disable}
                      style={{
                        backgroundColor: disable
                          ? 'rgb(41 37 36)'
                          : 'rgb(229 231 235)',
                        borderColor: '#000000',
                      }}
                    />
                    <Tooltip
                      style="light"
                      content={
                        <p className="text-xs text-stone-800">
                          Customer will no longer have access to the project
                          link or any additional content included in the
                          purchase
                        </p>
                      }
                      className="w-72"
                      arrow={false}
                    >
                      <p className="text-xs text-stone-800">
                        Remove project link from order
                      </p>
                    </Tooltip>
                  </div>
                  <div className="flex items-center gap-2">
                    <Switch
                      size="small"
                      onChange={handleFullAmount}
                      checked={full}
                      style={{
                        backgroundColor: full
                          ? 'rgb(41 37 36)'
                          : 'rgb(229 231 235)',
                        borderColor: '#000000',
                      }}
                    />
                    <p className="text-xs text-stone-800">Full amount</p>
                  </div>
                  <input
                    type="number"
                    placeholder="Amount"
                    className={`border border-gray-200 w-full bg-gray-50 ${
                      full ? 'opacity-50' : 'hover:bg-gray-200'
                    } rounded-md text-xs  focus:bg-gray-200 focus:border-gray-200`}
                    disabled={full}
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount > 0 ? amount : ''}
                  />
                </div>

                <div className="w-full flex items-center justify-between">
                  <p className="text-xs text-stone-600">
                    Refund amount:{' '}
                    <span className="font-bold">
                      {full ? (
                        <>
                          $
                          {order?.total?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      ) : (
                        <>
                          $
                          {Number(amount).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </span>
                  </p>
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      className="bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                      onClick={handleCloseMod}
                      disabled={refunding}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                      onClick={handleRefund}
                      disabled={refunding}
                    >
                      Refund
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Modal>
          <div className="w-full flex justify-between items-end max-w-3xl mx-auto">
            <div className="flex flex-col border border-gray-200 rounded-md p-2">
              <p className="text-sm text-stone-800">Order: {order?._id}</p>
              <p className="text-xs text-stone-600">
                <ElapsedTimeOrDate orderDate={order?.placedOn} />
              </p>
            </div>

            <div className="flex">
              <div className="flex items-center gap-4">
                {order?.viewed && (
                  <Tooltip
                    style="light"
                    arrow={false}
                    content={
                      <p className="text-xs text-stone-800">
                        Customer opened and viewed the project link
                      </p>
                    }
                  >
                    <div className="flex items-center gap-1">
                      <Eye size={14} />
                      <p className="text-stone-600 text-xs">Opened</p>
                    </div>
                  </Tooltip>
                )}
                {order?.reviewed && (
                  <Tooltip
                    style="light"
                    arrow={false}
                    content={
                      <p className="text-xs text-stone-800">
                        Customer reviewed the item
                      </p>
                    }
                  >
                    <div className="flex items-center gap-1">
                      <MessageSquare size={14} />
                      <p className="text-stone-600 text-xs">Review</p>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            {/* <Link
              to="/dashboard/orders"
              className="flex items-center text-xs text-stone-600"
            >
              Back
              <ChevronRight size={16} />
            </Link> */}
            <BackBtn />
          </div>

          <div className="flex flex-col max-w-3xl mx-auto border border-gray-200 rounded-md bg-white p-2 relative mt-2">
            <div className="flex items-start w-full gap-2">
              <div className="flex flex-col w-7/12 gap-2">
                <div className="flex flex-col">
                  <div className="border border-gray-200 rounded-md p-2 flex flex-col items-start gap-3 relative">
                    <div className="flex flex-col w-full">
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center gap-1">
                          <p className="text-stone-600 text-xs">Total:</p>
                          <p className="text-stone-800 text-sm font-bold">
                            $
                            {order?.total?.toLocaleString('en-US', {
                              minimumFractionDigits:
                                order?.total % 1 === 0 ? 0 : 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                        {order?.refunded ? (
                          <Badge color="info">Refunded</Badge>
                        ) : (
                          <Badge color="success">Paid</Badge>
                        )}
                      </div>
                    </div>

                    {order?.refunded ? (
                      <Timeline horizontal>
                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              Refund
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    Amount refunded to customer
                                  </p>
                                }
                                className="w-32"
                                arrow={false}
                              >
                                -${refundAmount}
                              </Tooltip>
                            </Timeline.Title>
                          </Timeline.Content>
                        </Timeline.Item>

                        {!order?.refundFull ? (
                          <Timeline.Item>
                            <Timeline.Point />

                            <Timeline.Content>
                              <Timeline.Time className="text-xs text-stone-600">
                                Fruntt
                              </Timeline.Time>
                              <Timeline.Title className="text-xs">
                                <Tooltip
                                  style="light"
                                  content={
                                    <p className="text-xs text-stone-800">
                                      Our platform fee
                                    </p>
                                  }
                                  className="w-32"
                                  arrow={false}
                                >
                                  {orderTotal < 5 ? '-$0.25' : '-$1.00'}
                                </Tooltip>
                              </Timeline.Title>
                            </Timeline.Content>
                          </Timeline.Item>
                        ) : (
                          ''
                        )}

                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              Stripe
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    Stripe processing fee from order payment
                                  </p>
                                }
                                arrow={false}
                                className="w-44"
                              >
                                -${fullStripeFee}
                              </Tooltip>
                            </Timeline.Title>
                          </Timeline.Content>
                        </Timeline.Item>

                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              Payout
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    What you earn from the sale after refund was
                                    issued
                                  </p>
                                }
                                arrow={false}
                                className="w-52"
                              >
                                $
                                {order?.taxId ? (
                                  <>
                                    {order?.total >= 5
                                      ? refHighFee.toFixed(2)
                                      : refLowFee.toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    {order?.total >= 5
                                      ? refHighFee.toFixed(2)
                                      : refLowFee.toFixed(2)}
                                  </>
                                )}
                              </Tooltip>
                            </Timeline.Title>
                          </Timeline.Content>
                        </Timeline.Item>
                      </Timeline>
                    ) : (
                      <>
                        {order?.total > 0 ? (
                          <Timeline horizontal>
                            {order?.total >= 5 ? (
                              <Timeline.Item>
                                <Timeline.Point />

                                <Timeline.Content>
                                  <Timeline.Time className="text-xs text-stone-600">
                                    Fruntt
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    <Tooltip
                                      style="light"
                                      content={
                                        <p className="text-xs text-stone-800">
                                          Our platform fee
                                        </p>
                                      }
                                      className="w-32"
                                      arrow={false}
                                    >
                                      -$1.00
                                    </Tooltip>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ) : (
                              <Timeline.Item>
                                <Timeline.Point />
                                <Timeline.Content>
                                  <Timeline.Time className="text-xs text-stone-600">
                                    Fruntt
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    <Tooltip
                                      style="light"
                                      content={
                                        <p className="text-xs text-stone-800">
                                          Our platform fee
                                        </p>
                                      }
                                      className="w-32"
                                      arrow={false}
                                    >
                                      -$0.25
                                    </Tooltip>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            )}
                            <Timeline.Item>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time className="text-xs text-stone-600">
                                  Stripe
                                </Timeline.Time>
                                <Timeline.Title className="text-xs">
                                  <Tooltip
                                    style="light"
                                    content={
                                      <p className="text-xs text-stone-800">
                                        Stripe processing fee
                                      </p>
                                    }
                                    arrow={false}
                                    className="w-44"
                                  >
                                    -${fullStripeFee}
                                  </Tooltip>
                                </Timeline.Title>
                              </Timeline.Content>
                            </Timeline.Item>
                            {order?.tax > 0 ? (
                              <Timeline.Item>
                                <Timeline.Point />
                                <Timeline.Content>
                                  <Timeline.Time className="text-xs text-stone-600">
                                    {/* {order?.taxType === 'vat' ? 'VAT' : 'Tax'} */}
                                    Tax/VAT
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    <Tooltip
                                      style="light"
                                      content={
                                        <p className="text-xs text-stone-800">
                                          Tax/VAT collected
                                        </p>
                                      }
                                      arrow={false}
                                      className="w-36"
                                    >
                                      -${(order?.tax / 100).toFixed(2)}
                                    </Tooltip>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ) : (
                              ''
                            )}
                            {order?.paymentOrigin === 'US' ? (
                              ''
                            ) : (
                              <Timeline.Item>
                                <Timeline.Point />
                                <Timeline.Content>
                                  <Timeline.Time className="text-xs text-stone-600">
                                    Intl
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    <Tooltip
                                      style="light"
                                      content={
                                        <p className="text-xs text-stone-800">
                                          International payment fee
                                        </p>
                                      }
                                      arrow={false}
                                      className="w-52"
                                    >
                                      -${IntFee.toFixed(2)}
                                    </Tooltip>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            )}

                            <Timeline.Item>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time className="text-xs text-stone-600">
                                  Payout
                                </Timeline.Time>
                                <Timeline.Title className="text-xs">
                                  <Tooltip
                                    style="light"
                                    content={
                                      <p className="text-xs text-stone-800">
                                        What you earn from the sale after fees
                                      </p>
                                    }
                                    arrow={false}
                                    className="w-52"
                                  >
                                    $
                                    {order?.taxId ? (
                                      <>
                                        {order?.total >= 5
                                          ? (
                                              youMakeAfterFrunttHighFee -
                                              taxAmount
                                            ).toFixed(2)
                                          : (
                                              youMakeAfterFrunttLowFee -
                                              taxAmount
                                            ).toFixed(2)}
                                      </>
                                    ) : (
                                      <>
                                        {order?.total >= 5
                                          ? youMakeAfterFrunttHighFee.toFixed(2)
                                          : youMakeAfterFrunttLowFee.toFixed(2)}
                                      </>
                                    )}
                                  </Tooltip>
                                </Timeline.Title>
                              </Timeline.Content>
                            </Timeline.Item>
                          </Timeline>
                        ) : (
                          <Timeline horizontal>
                            <Timeline.Item>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time className="text-xs text-stone-600">
                                  Fruntt
                                </Timeline.Time>
                                <Timeline.Title className="text-xs">
                                  <Tooltip
                                    style="light"
                                    content={
                                      <p className="text-xs text-stone-800">
                                        Our fee
                                      </p>
                                    }
                                    className="w-24"
                                    arrow={false}
                                  >
                                    -$0.00
                                  </Tooltip>
                                </Timeline.Title>
                              </Timeline.Content>
                            </Timeline.Item>

                            <Timeline.Item>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time className="text-xs text-stone-600">
                                  Stripe
                                </Timeline.Time>
                                <Timeline.Title className="text-xs">
                                  <Tooltip
                                    style="light"
                                    content={
                                      <p className="text-xs text-stone-800">
                                        Stripe processing fee
                                      </p>
                                    }
                                    className="w-44"
                                    arrow={false}
                                  >
                                    -$0.00
                                  </Tooltip>
                                </Timeline.Title>
                              </Timeline.Content>
                            </Timeline.Item>

                            {order?.country?.code === 'US' ? (
                              ''
                            ) : (
                              <Timeline.Item>
                                <Timeline.Point />
                                <Timeline.Content>
                                  <Timeline.Time className="text-xs text-stone-600">
                                    Intl
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    <Tooltip
                                      style="light"
                                      content={
                                        <p className="text-xs text-stone-800">
                                          International fee
                                        </p>
                                      }
                                      className="w-32"
                                      arrow={false}
                                    >
                                      -$0.00
                                    </Tooltip>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            )}

                            <Timeline.Item>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time className="text-xs text-stone-600">
                                  Payout
                                </Timeline.Time>
                                <Timeline.Title className="text-xs">
                                  <Tooltip
                                    style="light"
                                    content={
                                      <p className="text-xs text-stone-800">
                                        What you earn from the sale after fees
                                      </p>
                                    }
                                    className="w-32"
                                    arrow={false}
                                  >
                                    $0.00
                                  </Tooltip>
                                </Timeline.Title>
                              </Timeline.Content>
                            </Timeline.Item>
                          </Timeline>
                        )}
                      </>
                    )}
                    <div className="w-full flex justify-start">
                      {order?.refunded || order?.total === 0 ? (
                        ''
                      ) : (
                        <button
                          type="button"
                          onClick={() => setOpenRef(!openRef)}
                          className="bg-gray-200 text-xs text-stone-800 rounded-md pr-2 pl-2 p-0.5"
                        >
                          Refund
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Purchased by:</p>
                    <p className="text-xs text-stone-800">{order?.name}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Delivered to:</p>
                    <p className="text-xs text-stone-800">{order?.email}</p>
                  </div>

                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Located in:</p>

                    <div className="flex items-center">
                      <ReactCountryFlag
                        countryCode={order?.country?.code}
                        className="mr-1"
                      />
                      <p className="text-xs text-stone-800">
                        {order?.country?.name}
                      </p>
                    </div>
                  </div>
                  {order?.discount?.code ? (
                    <div className="flex flex-col">
                      <p className="text-stone-600 text-xs">
                        Discount code used:
                      </p>
                      <p className="text-xs text-stone-800">
                        {order?.discount?.code}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="w-5/12 flex flex-col relative">
                <img
                  src={order?.item?.coverImages[0]?.url}
                  className="rounded-md w-full h-52 object-fill"
                />
                {order?.discount?.code ? (
                  <div className="p-2 pb-1 pt-1 bg-gray-50 border border-gray-200 rounded-md absolute top-0 right-0 mt-2 mr-2">
                    {order?.discount?.percent ? (
                      <p className="text-stone-800 text-xs">
                        Customer saved{' '}
                        <span className="font-bold">
                          {order?.discount?.amount}%
                        </span>
                      </p>
                    ) : (
                      <p className="text-stone-800 text-xs">
                        Customer saved{' '}
                        <span className="font-bold">
                          ${order?.discount?.amount}
                        </span>
                      </p>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <p className="text-stone-600 text-xs mt-1">Item:</p>
                <p className="text-xs text-stone-800">{order?.item?.title}</p>
                {version ? (
                  <div className="flex flex-col mt-1">
                    <p className="text-stone-600 text-xs">Variation:</p>
                    <div className="p-2 rounded-md border border-gray-200 w-full flex flex-col">
                      <p className="text-xs text-stone-800">{version?.title}</p>
                      <p className="text-xs text-stone-600">
                        {version?.description}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {order?.reviewed ? (
            <div className="flex flex-col gap-2 max-w-3xl mx-auto border border-gray-200 rounded-md mt-2 p-2">
              <button
                type="button"
                onClick={() => setOpenRev(!openRev)}
                className="flex items-center justify-between"
              >
                <p className="text-xs text-stone-600">Review</p>
                {openRev ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </button>
              {openRev ? (
                <div className="flex flex-col gap-2 w-full mx-auto">
                  <Rating
                    value={order?.review?.rating}
                    precision={0.5}
                    size="small"
                    readOnly
                  />
                  {order?.review?.content ? (
                    <textarea
                      disabled
                      className="w-full bg-gray-50 rounded-md p-2 text-xs resize-none h-18 border-none"
                      value={order?.review?.content}
                    />
                  ) : (
                    <div className="w-full flex items-center justify-center rounded-md bg-gray-50 h-14">
                      <p className="text-stone-600 text-xs">
                        No written review
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default Desktop;
